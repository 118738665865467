.animsition, .animsition-overlay {
    position: relative;
    opacity: 0;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.animsition-overlay-slide {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: #ddd;
}
.animsition-loading {
    position: fixed;
    z-index: 2;
    top: 0;
    width: 100%;
    height: 100%;
    background: #e8eaf6 center center no-repeat;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.animsition-loading:after {
    font-size: 1pc;
    position: fixed;
    top: 50%;
    width: 100%;
    margin-top: 20px;
    /*content: 'Loading';*/
     background-image: url('../img/pre-loader.gif');
    text-align: center;
    color: #aaa;
    z-index: 3;
}
@-webkit-keyframes a {
    0% {
    opacity: 0;
}
to {
    opacity: 1;
}
}@keyframes a {
    0% {
    opacity: 0;
}
to {
    opacity: 1;
}
}.fade-in {
    -webkit-animation-name: a;
    animation-name: a;
}
@-webkit-keyframes b {
    0% {
    opacity: 1;
}
to {
    opacity: 0;
}
}@keyframes b {
    0% {
    opacity: 1;
}
to {
    opacity: 0;
}
}.fade-out {
    -webkit-animation-name: b;
    animation-name: b;
}
@-webkit-keyframes c {
    0% {
    -webkit-transform: translateY(500px);
    transform: translateY(500px);
    opacity: 0;
}
to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}
}@keyframes c {
    0% {
    -webkit-transform: translateY(500px);
    transform: translateY(500px);
    opacity: 0;
}
to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}
}.fade-in-up {
    -webkit-animation-name: c;
    animation-name: c;
}
@-webkit-keyframes d {
    0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}
to {
    -webkit-transform: translateY(-500px);
    transform: translateY(-500px);
    opacity: 0;
}
}@keyframes d {
    0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}
to {
    -webkit-transform: translateY(-500px);
    transform: translateY(-500px);
    opacity: 0;
}
}.fade-out-up {
    -webkit-animation-name: d;
    animation-name: d;
}
@-webkit-keyframes e {
    0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    opacity: 0;
}
to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}
}@keyframes e {
    0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    opacity: 0;
}
to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}
}.fade-in-up-sm {
    -webkit-animation-name: e;
    animation-name: e;
}
@-webkit-keyframes f {
    0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}
to {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0;
}
}@keyframes f {
    0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}
to {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0;
}
}.fade-out-up-sm {
    -webkit-animation-name: f;
    animation-name: f;
}
@-webkit-keyframes g {
    0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
}
to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}
}@keyframes g {
    0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
}
to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}
}.fade-in-up-lg {
    -webkit-animation-name: g;
    animation-name: g;
}
@-webkit-keyframes h {
    0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}
to {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
}
}@keyframes h {
    0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}
to {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
}
}.fade-out-up-lg {
    -webkit-animation-name: h;
    animation-name: h;
}
@-webkit-keyframes i {
    0% {
    -webkit-transform: translateY(-500px);
    transform: translateY(-500px);
    opacity: 0;
}
to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}
}@keyframes i {
    0% {
    -webkit-transform: translateY(-500px);
    transform: translateY(-500px);
    opacity: 0;
}
to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}
}.fade-in-down {
    -webkit-animation-name: i;
    animation-name: i;
}
@-webkit-keyframes j {
    0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}
to {
    -webkit-transform: translateY(500px);
    transform: translateY(500px);
    opacity: 0;
}
}@keyframes j {
    0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}
to {
    -webkit-transform: translateY(500px);
    transform: translateY(500px);
    opacity: 0;
}
}.fade-out-down {
    -webkit-animation-name: j;
    animation-name: j;
}
@-webkit-keyframes k {
    0% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0;
}
to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}
}@keyframes k {
    0% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0;
}
to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}
}.fade-in-down-sm {
    -webkit-animation-name: k;
    animation-name: k;
}
@-webkit-keyframes l {
    0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}
to {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    opacity: 0;
}
}@keyframes l {
    0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}
to {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    opacity: 0;
}
}.fade-out-down-sm {
    -webkit-animation-name: l;
    animation-name: l;
}
.fade-in-down-lg {
    -webkit-animation-name: i;
    animation-name: i;
}
@-webkit-keyframes m {
    0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}
to {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
}
}@keyframes m {
    0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}
to {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
}
}.fade-out-down-lg {
    -webkit-animation-name: m;
    animation-name: m;
}
@-webkit-keyframes n {
    0% {
    -webkit-transform: translateX(-500px);
    transform: translateX(-500px);
    opacity: 0;
}
to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}
}@keyframes n {
    0% {
    -webkit-transform: translateX(-500px);
    transform: translateX(-500px);
    opacity: 0;
}
to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}
}.fade-in-left {
    -webkit-animation-name: n;
    animation-name: n;
}
@-webkit-keyframes o {
    0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}
to {
    -webkit-transform: translateX(-500px);
    transform: translateX(-500px);
    opacity: 0;
}
}@keyframes o {
    0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}
to {
    -webkit-transform: translateX(-500px);
    transform: translateX(-500px);
    opacity: 0;
}
}.fade-out-left {
    -webkit-animation-name: o;
    animation-name: o;
}
@-webkit-keyframes p {
    0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0;
}
to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}
}@keyframes p {
    0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0;
}
to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}
}.fade-in-left-sm {
    -webkit-animation-name: p;
    animation-name: p;
}
@-webkit-keyframes q {
    0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}
to {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0;
}
}@keyframes q {
    0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}
to {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0;
}
}.fade-out-left-sm {
    -webkit-animation-name: q;
    animation-name: q;
}
@-webkit-keyframes r {
    0% {
    -webkit-transform: translateX(-1500px);
    transform: translateX(-1500px);
    opacity: 0;
}
to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}
}@keyframes r {
    0% {
    -webkit-transform: translateX(-1500px);
    transform: translateX(-1500px);
    opacity: 0;
}
to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}
}.fade-in-left-lg {
    -webkit-animation-name: r;
    animation-name: r;
}
@-webkit-keyframes s {
    0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}
to {
    -webkit-transform: translateX(-1500px);
    transform: translateX(-1500px);
    opacity: 0;
}
}@keyframes s {
    0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}
to {
    -webkit-transform: translateX(-1500px);
    transform: translateX(-1500px);
    opacity: 0;
}
}.fade-out-left-lg {
    -webkit-animation-name: s;
    animation-name: s;
}
@-webkit-keyframes t {
    0% {
    -webkit-transform: translateX(500px);
    transform: translateX(500px);
    opacity: 0;
}
to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}
}@keyframes t {
    0% {
    -webkit-transform: translateX(500px);
    transform: translateX(500px);
    opacity: 0;
}
to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}
}.fade-in-right {
    -webkit-animation-name: t;
    animation-name: t;
}
@-webkit-keyframes u {
    0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}
to {
    -webkit-transform: translateX(500px);
    transform: translateX(500px);
    opacity: 0;
}
}@keyframes u {
    0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}
to {
    -webkit-transform: translateX(500px);
    transform: translateX(500px);
    opacity: 0;
}
}.fade-out-right {
    -webkit-animation-name: u;
    animation-name: u;
}
@-webkit-keyframes v {
    0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0;
}
to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}
}@keyframes v {
    0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0;
}
to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}
}.fade-in-right-sm {
    -webkit-animation-name: v;
    animation-name: v;
}
@-webkit-keyframes w {
    0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}
to {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0;
}
}@keyframes w {
    0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}
to {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0;
}
}.fade-out-right-sm {
    -webkit-animation-name: w;
    animation-name: w;
}
@-webkit-keyframes x {
    0% {
    -webkit-transform: translateX(1500px);
    transform: translateX(1500px);
    opacity: 0;
}
to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}
}@keyframes x {
    0% {
    -webkit-transform: translateX(1500px);
    transform: translateX(1500px);
    opacity: 0;
}
to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}
}.fade-in-right-lg {
    -webkit-animation-name: x;
    animation-name: x;
}
@-webkit-keyframes y {
    0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}
to {
    -webkit-transform: translateX(1500px);
    transform: translateX(1500px);
    opacity: 0;
}
}@keyframes y {
    0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}
to {
    -webkit-transform: translateX(1500px);
    transform: translateX(1500px);
    opacity: 0;
}
}.fade-out-right-lg {
    -webkit-animation-name: y;
    animation-name: y;
}
@-webkit-keyframes z {
    0% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transform-origin: center center;
    opacity: 0;
}
0%, to {
    -webkit-transform-origin: center center;
}
to {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    transform-origin: center center;
    opacity: 1;
}
}@keyframes z {
    0% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transform-origin: center center;
    opacity: 0;
}
0%, to {
    -webkit-transform-origin: center center;
}
to {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    transform-origin: center center;
    opacity: 1;
}
}.rotate-in {
    -webkit-animation-name: z;
    animation-name: z;
}
@-webkit-keyframes A {
    0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    transform-origin: center center;
    opacity: 1;
}
0%, to {
    -webkit-transform-origin: center center;
}
to {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    transform-origin: center center;
    opacity: 0;
}
}@keyframes A {
    0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    transform-origin: center center;
    opacity: 1;
}
0%, to {
    -webkit-transform-origin: center center;
}
to {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    transform-origin: center center;
    opacity: 0;
}
}.rotate-out {
    -webkit-animation-name: A;
    animation-name: A;
}
@-webkit-keyframes B {
    0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    transform-origin: center center;
    opacity: 0;
}
0%, to {
    -webkit-transform-origin: center center;
}
to {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    transform-origin: center center;
    opacity: 1;
}
}@keyframes B {
    0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    transform-origin: center center;
    opacity: 0;
}
0%, to {
    -webkit-transform-origin: center center;
}
to {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    transform-origin: center center;
    opacity: 1;
}
}.rotate-in-sm {
    -webkit-animation-name: B;
    animation-name: B;
}
@-webkit-keyframes C {
    0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    transform-origin: center center;
    opacity: 1;
}
0%, to {
    -webkit-transform-origin: center center;
}
to {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    transform-origin: center center;
    opacity: 0;
}
}@keyframes C {
    0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    transform-origin: center center;
    opacity: 1;
}
0%, to {
    -webkit-transform-origin: center center;
}
to {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    transform-origin: center center;
    opacity: 0;
}
}.rotate-out-sm {
    -webkit-animation-name: C;
    animation-name: C;
}
@-webkit-keyframes D {
    0% {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
    transform-origin: center center;
    opacity: 0;
}
0%, to {
    -webkit-transform-origin: center center;
}
to {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    transform-origin: center center;
    opacity: 1;
}
}@keyframes D {
    0% {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
    transform-origin: center center;
    opacity: 0;
}
0%, to {
    -webkit-transform-origin: center center;
}
to {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    transform-origin: center center;
    opacity: 1;
}
}.rotate-in-lg {
    -webkit-animation-name: D;
    animation-name: D;
}
@-webkit-keyframes E {
    0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    transform-origin: center center;
    opacity: 1;
}
0%, to {
    -webkit-transform-origin: center center;
}
to {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    transform-origin: center center;
    opacity: 0;
}
}@keyframes E {
    0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    transform-origin: center center;
    opacity: 1;
}
0%, to {
    -webkit-transform-origin: center center;
}
to {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    transform-origin: center center;
    opacity: 0;
}
}.rotate-out-lg {
    -webkit-animation-name: E;
    animation-name: E;
}
@-webkit-keyframes F {
    0% {
    -webkit-transform: perspective(550px) rotateX(90deg);
    transform: perspective(550px) rotateX(90deg);
    opacity: 0;
}
to {
    -webkit-transform: perspective(550px) rotateX(0deg);
    transform: perspective(550px) rotateX(0deg);
    opacity: 1;
}
}@keyframes F {
    0% {
    -webkit-transform: perspective(550px) rotateX(90deg);
    transform: perspective(550px) rotateX(90deg);
    opacity: 0;
}
to {
    -webkit-transform: perspective(550px) rotateX(0deg);
    transform: perspective(550px) rotateX(0deg);
    opacity: 1;
}
}.flip-in-x {
    -webkit-animation-name: F;
    animation-name: F;
    -webkit-backface-visibility: visible!important;
    backface-visibility: visible!important;
}
@-webkit-keyframes G {
    0% {
    -webkit-transform: perspective(550px) rotateX(0deg);
    transform: perspective(550px) rotateX(0deg);
    opacity: 1;
}
to {
    -webkit-transform: perspective(550px) rotateX(90deg);
    transform: perspective(550px) rotateX(90deg);
    opacity: 0;
}
}@keyframes G {
    0% {
    -webkit-transform: perspective(550px) rotateX(0deg);
    transform: perspective(550px) rotateX(0deg);
    opacity: 1;
}
to {
    -webkit-transform: perspective(550px) rotateX(90deg);
    transform: perspective(550px) rotateX(90deg);
    opacity: 0;
}
}.flip-out-x {
    -webkit-animation-name: G;
    animation-name: G;
    -webkit-backface-visibility: visible!important;
    backface-visibility: visible!important;
}
@-webkit-keyframes H {
    0% {
    -webkit-transform: perspective(100px) rotateX(90deg);
    transform: perspective(100px) rotateX(90deg);
    opacity: 0;
}
to {
    -webkit-transform: perspective(100px) rotateX(0deg);
    transform: perspective(100px) rotateX(0deg);
    opacity: 1;
}
}@keyframes H {
    0% {
    -webkit-transform: perspective(100px) rotateX(90deg);
    transform: perspective(100px) rotateX(90deg);
    opacity: 0;
}
to {
    -webkit-transform: perspective(100px) rotateX(0deg);
    transform: perspective(100px) rotateX(0deg);
    opacity: 1;
}
}.flip-in-x-nr {
    -webkit-animation-name: H;
    animation-name: H;
    -webkit-backface-visibility: visible!important;
    backface-visibility: visible!important;
}
@-webkit-keyframes I {
    0% {
    -webkit-transform: perspective(100px) rotateX(0deg);
    transform: perspective(100px) rotateX(0deg);
    opacity: 1;
}
to {
    -webkit-transform: perspective(100px) rotateX(90deg);
    transform: perspective(100px) rotateX(90deg);
    opacity: 0;
}
}@keyframes I {
    0% {
    -webkit-transform: perspective(100px) rotateX(0deg);
    transform: perspective(100px) rotateX(0deg);
    opacity: 1;
}
to {
    -webkit-transform: perspective(100px) rotateX(90deg);
    transform: perspective(100px) rotateX(90deg);
    opacity: 0;
}
}.flip-out-x-nr {
    -webkit-animation-name: I;
    animation-name: I;
    -webkit-backface-visibility: visible!important;
    backface-visibility: visible!important;
}
@-webkit-keyframes J {
    0% {
    -webkit-transform: perspective(1000px) rotateX(90deg);
    transform: perspective(1000px) rotateX(90deg);
    opacity: 0;
}
to {
    -webkit-transform: perspective(1000px) rotateX(0deg);
    transform: perspective(1000px) rotateX(0deg);
    opacity: 1;
}
}@keyframes J {
    0% {
    -webkit-transform: perspective(1000px) rotateX(90deg);
    transform: perspective(1000px) rotateX(90deg);
    opacity: 0;
}
to {
    -webkit-transform: perspective(1000px) rotateX(0deg);
    transform: perspective(1000px) rotateX(0deg);
    opacity: 1;
}
}.flip-in-x-fr {
    -webkit-animation-name: J;
    animation-name: J;
    -webkit-backface-visibility: visible!important;
    backface-visibility: visible!important;
}
@-webkit-keyframes K {
    0% {
    -webkit-transform: perspective(1000px) rotateX(0deg);
    transform: perspective(1000px) rotateX(0deg);
    opacity: 1;
}
to {
    -webkit-transform: perspective(1000px) rotateX(90deg);
    transform: perspective(1000px) rotateX(90deg);
    opacity: 0;
}
}@keyframes K {
    0% {
    -webkit-transform: perspective(1000px) rotateX(0deg);
    transform: perspective(1000px) rotateX(0deg);
    opacity: 1;
}
to {
    -webkit-transform: perspective(1000px) rotateX(90deg);
    transform: perspective(1000px) rotateX(90deg);
    opacity: 0;
}
}.flip-out-x-fr {
    -webkit-animation-name: K;
    animation-name: K;
    -webkit-backface-visibility: visible!important;
    backface-visibility: visible!important;
}
@-webkit-keyframes L {
    0% {
    -webkit-transform: perspective(550px) rotateY(90deg);
    transform: perspective(550px) rotateY(90deg);
    opacity: 0;
}
to {
    -webkit-transform: perspective(550px) rotateY(0deg);
    transform: perspective(550px) rotateY(0deg);
    opacity: 1;
}
}@keyframes L {
    0% {
    -webkit-transform: perspective(550px) rotateY(90deg);
    transform: perspective(550px) rotateY(90deg);
    opacity: 0;
}
to {
    -webkit-transform: perspective(550px) rotateY(0deg);
    transform: perspective(550px) rotateY(0deg);
    opacity: 1;
}
}.flip-in-y {
    -webkit-animation-name: L;
    animation-name: L;
    -webkit-backface-visibility: visible!important;
    backface-visibility: visible!important;
}
@-webkit-keyframes M {
    0% {
    -webkit-transform: perspective(550px) rotateY(0deg);
    transform: perspective(550px) rotateY(0deg);
    opacity: 1;
}
to {
    -webkit-transform: perspective(550px) rotateY(90deg);
    transform: perspective(550px) rotateY(90deg);
    opacity: 0;
}
}@keyframes M {
    0% {
    -webkit-transform: perspective(550px) rotateY(0deg);
    transform: perspective(550px) rotateY(0deg);
    opacity: 1;
}
to {
    -webkit-transform: perspective(550px) rotateY(90deg);
    transform: perspective(550px) rotateY(90deg);
    opacity: 0;
}
}.flip-out-y {
    -webkit-animation-name: M;
    animation-name: M;
    -webkit-backface-visibility: visible!important;
    backface-visibility: visible!important;
}
@-webkit-keyframes N {
    0% {
    -webkit-transform: perspective(100px) rotateY(90deg);
    transform: perspective(100px) rotateY(90deg);
    opacity: 0;
}
to {
    -webkit-transform: perspective(100px) rotateY(0deg);
    transform: perspective(100px) rotateY(0deg);
    opacity: 1;
}
}@keyframes N {
    0% {
    -webkit-transform: perspective(100px) rotateY(90deg);
    transform: perspective(100px) rotateY(90deg);
    opacity: 0;
}
to {
    -webkit-transform: perspective(100px) rotateY(0deg);
    transform: perspective(100px) rotateY(0deg);
    opacity: 1;
}
}.flip-in-y-nr {
    -webkit-animation-name: N;
    animation-name: N;
    -webkit-backface-visibility: visible!important;
    backface-visibility: visible!important;
}
@-webkit-keyframes O {
    0% {
    -webkit-transform: perspective(100px) rotateY(0deg);
    transform: perspective(100px) rotateY(0deg);
    opacity: 1;
}
to {
    -webkit-transform: perspective(100px) rotateY(90deg);
    transform: perspective(100px) rotateY(90deg);
    opacity: 0;
}
}@keyframes O {
    0% {
    -webkit-transform: perspective(100px) rotateY(0deg);
    transform: perspective(100px) rotateY(0deg);
    opacity: 1;
}
to {
    -webkit-transform: perspective(100px) rotateY(90deg);
    transform: perspective(100px) rotateY(90deg);
    opacity: 0;
}
}.flip-out-y-nr {
    -webkit-animation-name: O;
    animation-name: O;
    -webkit-backface-visibility: visible!important;
    backface-visibility: visible!important;
}
@-webkit-keyframes P {
    0% {
    -webkit-transform: perspective(1000px) rotateY(90deg);
    transform: perspective(1000px) rotateY(90deg);
    opacity: 0;
}
to {
    -webkit-transform: perspective(1000px) rotateY(0deg);
    transform: perspective(1000px) rotateY(0deg);
    opacity: 1;
}
}@keyframes P {
    0% {
    -webkit-transform: perspective(1000px) rotateY(90deg);
    transform: perspective(1000px) rotateY(90deg);
    opacity: 0;
}
to {
    -webkit-transform: perspective(1000px) rotateY(0deg);
    transform: perspective(1000px) rotateY(0deg);
    opacity: 1;
}
}.flip-in-y-fr {
    -webkit-animation-name: P;
    animation-name: P;
    -webkit-backface-visibility: visible!important;
    backface-visibility: visible!important;
}
@-webkit-keyframes Q {
    0% {
    -webkit-transform: perspective(1000px) rotateY(0deg);
    transform: perspective(1000px) rotateY(0deg);
    opacity: 1;
}
to {
    -webkit-transform: perspective(1000px) rotateY(90deg);
    transform: perspective(1000px) rotateY(90deg);
    opacity: 0;
}
}@keyframes Q {
    0% {
    -webkit-transform: perspective(1000px) rotateY(0deg);
    transform: perspective(1000px) rotateY(0deg);
    opacity: 1;
}
to {
    -webkit-transform: perspective(1000px) rotateY(90deg);
    transform: perspective(1000px) rotateY(90deg);
    opacity: 0;
}
}.flip-out-y-fr {
    -webkit-animation-name: Q;
    animation-name: Q;
    -webkit-backface-visibility: visible!important;
    backface-visibility: visible!important;
}
@-webkit-keyframes R {
    0% {
    -webkit-transform: scale(.7);
    transform: scale(.7);
    opacity: 0;
}
to {
    opacity: 1;
}
}@keyframes R {
    0% {
    -webkit-transform: scale(.7);
    transform: scale(.7);
    opacity: 0;
}
to {
    opacity: 1;
}
}.zoom-in {
    -webkit-animation-name: R;
    animation-name: R;
}
@-webkit-keyframes S {
    0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
}
50% {
    -webkit-transform: scale(.7);
    transform: scale(.7);
}
50%, to {
    opacity: 0;
}
}@keyframes S {
    0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
}
50% {
    -webkit-transform: scale(.7);
    transform: scale(.7);
}
50%, to {
    opacity: 0;
}
}.zoom-out {
    -webkit-animation-name: S;
    animation-name: S;
}
@-webkit-keyframes T {
    0% {
    -webkit-transform: scale(.95);
    transform: scale(.95);
    opacity: 0;
}
to {
    opacity: 1;
}
}@keyframes T {
    0% {
    -webkit-transform: scale(.95);
    transform: scale(.95);
    opacity: 0;
}
to {
    opacity: 1;
}
}.zoom-in-sm {
    -webkit-animation-name: T;
    animation-name: T;
}
@-webkit-keyframes U {
    0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
}
50% {
    -webkit-transform: scale(.95);
    transform: scale(.95);
}
50%, to {
    opacity: 0;
}
}@keyframes U {
    0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
}
50% {
    -webkit-transform: scale(.95);
    transform: scale(.95);
}
50%, to {
    opacity: 0;
}
}.zoom-out-sm {
    -webkit-animation-name: U;
    animation-name: U;
}
@-webkit-keyframes V {
    0% {
    -webkit-transform: scale(.4);
    transform: scale(.4);
    opacity: 0;
}
to {
    opacity: 1;
}
}@keyframes V {
    0% {
    -webkit-transform: scale(.4);
    transform: scale(.4);
    opacity: 0;
}
to {
    opacity: 1;
}
}.zoom-in-lg {
    -webkit-animation-name: V;
    animation-name: V;
}
@-webkit-keyframes W {
    0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
}
50% {
    -webkit-transform: scale(.4);
    transform: scale(.4);
}
50%, to {
    opacity: 0;
}
}@keyframes W {
    0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
}
50% {
    -webkit-transform: scale(.4);
    transform: scale(.4);
}
50%, to {
    opacity: 0;
}
}.zoom-out-lg {
    -webkit-animation-name: W;
    animation-name: W;
}
.overlay-slide-in-top {
    top: 0;
    height: 0;
    -webkit-animation-name: Y;
    animation-name: Y;
}
@-webkit-keyframes X {
    0% {
    height: 0;
}
to {
    height: 100%}
}@keyframes X {
    0% {
    height: 0;
}
to {
    height: 100%}
}.overlay-slide-out-top {
    top: 0;
    height: 100%;
    -webkit-animation-name: X;
    animation-name: X;
}
@-webkit-keyframes Y {
    0% {
    height: 100%}
to {
    height: 0;
}
}@keyframes Y {
    0% {
    height: 100%}
to {
    height: 0;
}
}.overlay-slide-in-bottom {
    bottom: 0;
    height: 0;
    -webkit-animation-name: Y;
    animation-name: Y;
}
@-webkit-keyframes Z {
    0% {
    height: 0;
}
to {
    height: 100%}
}@keyframes Z {
    0% {
    height: 0;
}
to {
    height: 100%}
}.overlay-slide-out-bottom {
    bottom: 0;
    height: 100%;
    -webkit-animation-name: Z;
    animation-name: Z;
}
.overlay-slide-in-left {
    width: 0;
    -webkit-animation-name: bb;
    animation-name: bb;
}
@-webkit-keyframes ab {
    0% {
    width: 0;
}
to {
    width: 100%}
}@keyframes ab {
    0% {
    width: 0;
}
to {
    width: 100%}
}.overlay-slide-out-left {
    left: 0;
    width: 100%;
    -webkit-animation-name: ab;
    animation-name: ab;
}
@-webkit-keyframes bb {
    0% {
    width: 100%}
to {
    width: 0;
}
}@keyframes bb {
    0% {
    width: 100%}
to {
    width: 0;
}
}.overlay-slide-in-right {
    right: 0;
    width: 0;
    -webkit-animation-name: bb;
    animation-name: bb;
}
@-webkit-keyframes cb {
    0% {
    width: 0;
}
to {
    width: 100%}
}@keyframes cb {
    0% {
    width: 0;
}
to {
    width: 100%}
}.overlay-slide-out-right {
    right: 0;
    width: 100%;
    -webkit-animation-name: cb;
    animation-name: cb;
}
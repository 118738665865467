/* This css file is to over write bootstarp css
---------------------------------------------------------------------- */
/*
Theme Name: angles
Theme URI: http://jeweltheme.com/
Author: Jewel Theme
Author URI: http://jeweltheme.com/
Description: angles HTML5 Template by Jewel Theme
Version: 1.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Tags: html, themplate, angles, jeweltheme, business
*---------------------------------------------------------------------- */

/* ----------- Table Of Contents ------------*/

/* 1 - Google Fonts
/* 2 - General Styles
/* 3 - Template Menu & Layout Styles
/* 4 - angles Slider 
/* 5 - Profile Section
/* 6 - Services Section
/* 7 - Works Section
/* 8 - Blog Section
/* 9 - Clients Section
/* 10 - Contact Section
/* 11 - Blog Single page
/* 12 - Work Single Page
/* 13 - Pagination
/* 14 - Footer

/* ----------- Table Of Contents ------------*/


/* 1 ------ Google Fonts ------- */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url(https://fonts.gstatic.com/s/opensans/v16/mem5YaGs126MiZpBA-UN_r8OUuhpKKSTjw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v16/mem8YaGs126MiZpBA-UFVZ0bf8pkAg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v16/mem5YaGs126MiZpBA-UN7rgOUuhpKKSTjw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'), url(https://fonts.gstatic.com/s/opensans/v16/mem5YaGs126MiZpBA-UN8rsOUuhpKKSTjw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Montserrat", 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v13/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Montserrat", 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v13/JTURjIg1_i6t8kCHKm45_dJE3gnD_vx3rCs.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Montserrat", 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-style: normal;
  font-weight: 900;
  src: local('Montserrat Black'), local('Montserrat-Black'), url(https://fonts.gstatic.com/s/montserrat/v13/JTURjIg1_i6t8kCHKm45_epG3gnD_vx3rCs.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


/* 2 ------ General Styles ------- */

/*
* reset.css
* by Paulo Melo
*/


/**
* At-rules - Set the encoding of the style sheet to Unicode UTF-8
* Mozilla Developer Network [https://developer.mozilla.org/en-US/docs/Web/CSS/@charset]
*
* @charset "UTF-8";
*/


/**
* Basic UI - Apply a natural box layout model to all elements. [Firefox, WebKit, Opera and IE8+]
* Paul Irish [http://www.paulirish.com/2012/box-sizing-border-box-ftw/]
*/

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html * {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}


/**
* Basic Box Model - Correct 'block' display not defined in [IE 8+]
*/

article,
aside,
datagrid,
datalist,
details,
dialog,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

abbr,
eventsource,
mark,
meter,
time,
output,
bb {
  display: inline;
}


/* Basic Box Model - Remove default Margin. */

body,
blockquote,
figure,
form,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}


/* Basic Box Model - Remove default Margin and Padding. */

button,
code,
dd,
dl,
dt,
fieldset,
hr,
input,
legend,
menu,
ol,
pre,
select,
td,
textarea,
th,
ul {
  margin: 0;
  padding: 0;
}


/* Remove default Border. */

a img,
abbr,
button,
fieldset,
hr,
iframe,
input,
select,
table,
textarea {
  border: 0 none;
}


/* Alignments */

label,
button,
input,
select,
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

body,
:root {
  overflow-x: hidden;
}

@media only screen and (max-device-width: 1024px) {
  :root {
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
}

button,
input,
optgroup,
option,
select,
textarea {
  font: normal normal normal 100%/normal -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  text-rendering: optimizespeed;
}

address,
b,
caption,
cite,
code,
dfn,
em,
h1,
h2,
h3,
h4,
h5,
h6,
i,
optgroup,
option,
strong,
th,
var {
  font-weight: normal;
  font-style: normal;
  text-rendering: optimizespeed;
}

table {
  font-size: inherit;
  width: 100%;
}

address,
p,
dl,
blockquote,
pre,
td,
th,
label,
ol,
ul,
textarea {
  line-height: 1.61803;
  text-rendering: optimizespeed;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1rem;
}

p+h1,
p+h2,
p+h3,
p+h4,
p+h5,
p+h6 {
  margin-top: 2rem;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
  margin-bottom: 0;
}

p {
  margin-bottom: 1rem;
}

p:first-child {
  margin-top: 0;
}

p:last-child {
  margin-bottom: 0;
}

caption,
th {
  text-align: left;
}

small {
  font-size: 80%;
}

ul,
menu,
dir,
ol {
  list-style: none;
}

html:focus,
a:hover,
a:active {
  outline: 0;
}

:active,
:focus {
  outline: 0 !important;
}

a {
  text-decoration: none !important;
  color: inherit;
}

.link:hover {
  cursor: pointer;
  text-decoration: underline;
}

img,
table,
td,
blockquote,
code,
pre,
textarea,
input,
svg {
  max-width: 100%;
  height: auto;
}

object,
embed {
  position: relative;
  max-width: 100%;
  height: 100%;
}

embed,
img,
object,
svg {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-drag: none;
}

dl,
dt,
dd {
  vertical-align: top;
}

.lt-ie8 img {
  -ms-interpolation-mode: bicubic;
}

abbr[title] {
  border-bottom: 1px dotted;
}

[hidden] {
  display: none;
}

[readonly],
[disabled] {
  cursor: default;
  opacity: .75;
}

[disabled],
[readonly],
[unselectable] {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
}

blockquote:last-child,
pre:last-child,
table:last-child {
  margin-bottom: 0;
}

code,
pre,
samp {
  white-space: pre-wrap;
}

code {
  line-height: 1;
}

kbd {
  padding: 0 .25rem;
  border: solid 1px;
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

abbr[title],
dfn[title] {
  cursor: help;
  border-bottom: dotted 1px;
}

body {
  background: #eee;
  color: rgba(0, 0, 0, 0.8);
  text-rendering: optimizespeed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

@media (max-device-width:768px) {
  html body {
    color: black;
  }
}

p:first-child {
  margin-top: 0;
}

p:last-child {
  margin-bottom: 0;
}

a,
.btn,
input,
select,
textarea,
.form-control {
  -webkit-transition: all .4s cubic-bezier(.160, .560, .170, .999);
  -o-transition: all .4s cubic-bezier(.160, .560, .170, .999);
  transition: all .4s cubic-bezier(.160, .560, .170, .999);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

::-moz-selection {
  color: white;
  background: #f4851f;
}

::selection {
  color: white;
  background: #f4851f;
}

::-webkit-input-placeholder {
  -webkit-transition: all 0.4s cubic-bezier(.160, .560, .170, .999);
  -o-transition: all 0.4s cubic-bezier(.160, .560, .170, .999);
  transition: all 0.4s cubic-bezier(.160, .560, .170, .999);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: .65;
}

::-moz-placeholder {
  -webkit-transition: all 0.4s cubic-bezier(.160, .560, .170, .999);
  -o-transition: all 0.4s cubic-bezier(.160, .560, .170, .999);
  transition: all 0.4s cubic-bezier(.160, .560, .170, .999);
  transform: translate3d(0, 0, 0);
  opacity: .65;
}

:-ms-input-placeholder {
  -webkit-transition: all 0.4s cubic-bezier(.160, .560, .170, .999);
  -o-transition: all 0.4s cubic-bezier(.160, .560, .170, .999);
  transition: all 0.4s cubic-bezier(.160, .560, .170, .999);
  transform: translate3d(0, 0, 0);
  opacity: .65;
}

input:hover::-webkit-input-placeholder {
  opacity: 1;
}

textarea:hover::-webkit-input-placeholder {
  opacity: 1;
}

input:focus::-webkit-input-placeholder {
  -webkit-transition-delay: 1s;
  transition-delay: 1s;
  opacity: .5;
}

textarea:focus::-webkit-input-placeholder {
  -webkit-transition-delay: 1s;
  transition-delay: 1s;
  opacity: .5;
}

input:hover:-moz-placeholder {
  opacity: 1;
}

textarea:hover:-moz-placeholder {
  opacity: 1;
}

input:focus:-moz-placeholder {
  -webkit-transition-delay: 1s;
  transition-delay: 1s;
  opacity: .5;
}

textarea:focus:-moz-placeholder {
  -webkit-transition-delay: 1s;
  transition-delay: 1s;
  opacity: .5;
}

input:hover:-ms-input-placeholder {
  opacity: 1;
}

textarea:hover:-ms-input-placeholder {
  opacity: 1;
}

input:focus:-ms-input-placeholder {
  -webkit-transition-delay: 1s;
  transition-delay: 1s;
  opacity: .5;
}

textarea:focus:-ms-input-placeholder {
  -webkit-transition-delay: 1s;
  transition-delay: 1s;
  opacity: .5;
}

iframe,
audio {
  width: 100%;
}

html strong {
  font-weight: bold;
}

.btn-container {
  clear: both;
}

.btn {
  border-radius: 0px;
}

.load-more {
  background: #f4851f;
  border: 1px solid #f4851f;
  border-radius: 0px;
  color: #ffffff;
  font-family: 'Open Sans';
  font-size: 19px;
  font-weight: 800;
  margin-top: 80px;
  padding: 20px 50px;
  text-transform: uppercase;
}

.load-more:hover {
  background: #ffffff;
  color: #f4851f;
}

.angles-container .page-title {
  color: #313332;
  font-size: 30px;
  font-family: "Montserrat", 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: 700;
  padding-bottom: 4px;
  padding-top: 10px;
  text-transform: uppercase;
}

.home .page-title {
  font-family: Montserrat, sans-serif;
  color: #ec6822;
  margin-top: 5%;
}

.angles-container .page-title span {
  font-weight: 400;
}

.page-subtitle {
  color: #313332;
  display: inline-block;
  font-size: 15px;
  font-family: "Montserrat", 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: 400;
  padding: 0 15px 12px 15px;
  position: relative;
  text-transform: uppercase;
}

.page-subtitle:before {
  content: '';
  border: 4px solid #b0bec5;
  border-top: none;
  height: 100%;
  width: 100%;
  top: 3px;
  left: 0;
  position: absolute;
}

.content-top {
  padding-right: 30px;
  padding-left: 30px;
}

.content-bottom {
  padding: 64px 0;
}

.item-title,
.section-title {
  color: #313332;
  font-size: 25px;

  font-weight: 800;
  text-transform: uppercase;
  padding-bottom: 23px;
}

.item-icon {
  color: #313332;
  font-size: 70px;
  padding-bottom: 27px;
}

.item-description {

  font-size: 16px;
  line-height: 23px;
}

.bottom-padding {
  clear: both;
  padding-top: 60px;
}

.description {
  color: #575d63;

  font-size: 16px;
  line-height: 26px;
  padding-top: 0px;
}

.description strong {
  display: block;
}

/* 3 ------- Template Menu & Layout Styles ---------*/

.angles-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.angles-container .angles-wrapper {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: #FFFFFF;
}

.angles-container .angles-wrapper_content {
  z-index: 1;
  position: relative;
  height: 100%;
  background: #313332;
  overflow: hidden;
}

.angles-container .angles-wrapper_menu {
  position: absolute;
  left: 0;
  top: 219px;
}

.angles-container .angles-wrapper_menu {
  position: absolute;
  left: 0;
  top: 219px;
}

.angles-container .angles-menu-item {
  z-index: -1;
  position: absolute;
  left: 0;
  width: 15px;
  height: 15px;
  cursor: pointer;
  opacity: 0;

  -webkit-perspective: 800px;
  perspective: 800px;

  -webkit-transition: .7s cubic-bezier(.160, .560, .170, .999);
  -o-transition: .7s cubic-bezier(.160, .560, .170, .999);
  transition: .7s cubic-bezier(.160, .560, .170, .999);
}

.angles-container .angles-menu-item svg,
.separator svg {
  fill: #f4851f;
  stroke-width: 0
}

.angles-container .angles-menu-item-1 {
  top: -102.4px;
}

.angles-container .menu-active .angles-menu-item-1 {
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

.angles-container .angles-menu-item-2 {
  top: -4px;
}

.angles-container .menu-active .angles-menu-item-2 {
  -webkit-transition-delay: 0.1s;
  -o-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.angles-container .angles-menu-item-3 {
  top: 73px;
}

.angles-container .menu-active .angles-menu-item-3 {
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.angles-container .angles-menu-item-4 {
  top: 171px;
}

.angles-container .menu-active .angles-menu-item-4 {
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.angles-container .angles-menu-item-5 {
  top: 248px;
}

.angles-container .menu-active .angles-menu-item-5 {
  -webkit-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.angles-container .angles-menu-item-6 {
  top: 346px;
}

.angles-container .menu-active .angles-menu-item-6 {
  -webkit-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.angles-container .angles-menu-item-7 {
  top: 423px;
}

.angles-container .menu-active .angles-menu-item-7 {
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.angles-container .angles-menu-item:nth-child(even):after {
  -webkit-transform: translate3d(65px, -10px, 0) rotate(0deg);
  -ms-transform: translate3d(65px, -10px, 0) rotate(0deg);
  transform: translate3d(65px, -10px, 0) rotate(0deg);
}

.angles-container .angles-menu-item:nth-child(even) .angles-menu-item-content {
  -webkit-transform: translate3d(6rem, 0, 0);
  -ms-transform: translate3d(6rem, 0, 0);
  transform: translate3d(6rem, 0, 0);
}

.angles-container .angles-menu-item-1 {
  -webkit-transform: translate(0, -224px);
  -ms-transform: translate(0, -224px);
  transform: translate(0, -224px);
}

.angles-container .angles-menu-item-2 {
  -webkit-transform: translate(32px, -352px);
  -ms-transform: translate(32px, -352px);
  transform: translate(32px, -352px);
}

.angles-container .angles-menu-item-3 {
  -webkit-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
}

.angles-container .angles-menu-item-4 {
  -webkit-transform: translate(32px, 352px);
  -ms-transform: translate(32px, 352px);
  transform: translate(32px, 352px);
}

.angles-container .angles-menu-item-5 {
  -webkit-transform: translate(0, 224px);
  -ms-transform: translate(0, 224px);
  transform: translate(0, 224px);
}

.angles-container .angles-menu-item-6 {
  -webkit-transform: translate(-32px, 224px);
  -ms-transform: translate(-32px, 224px);
  transform: translate(-32px, 224px);
}

.angles-container .angles-menu-item-7 {
  -webkit-transform: translate(-32px, 352px);
  -ms-transform: translate(-32px, 352px);
  transform: translate(-32px, 352px);
}

.angles-container .menu-active .angles-menu-item {
  z-index: 2;
  opacity: 1;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.angles-container .angles-menu-item:after {
  content: "\f0c9";
  font-family: 'Fontawesome';
  color: #ffffff;
  font-size: 35px;
  position: absolute;
  left: 10px;
  top: 41px;
  width: 100px;
  opacity: 0;
  height: 100px;
  text-align: center;
  line-height: 100px;

  -webkit-transition: transform 0.7s, opacity 0.7s;
  -o-transition: transform 0.7s, opacity 0.7s;
  -webkit-transition: opacity 0.7s, -webkit-transform 0.7s;
  transition: opacity 0.7s, -webkit-transform 0.7s;
  transition: transform 0.7s, opacity 0.7s;
  transition: transform 0.7s, opacity 0.7s, -webkit-transform 0.7s;

  -webkit-transition-delay: inherit;
  -o-transition-delay: inherit;
  transition-delay: inherit;
}

.angles-wrapper.is-sticky .angles-menu-item:after {
  height: 84px;
  line-height: 104px;
}

.angles-container .angles-menu-item-content {
  position: absolute;
  margin-top: 80px;
  left: 35px;
  top: 0;
  text-align: center;
  font-size: 30px;
  color: #fff;
  z-index: 1;

  -webkit-transition: -webkit-transform 0.25s, opacity 0.25s;
  -o-transition: -o-transform 0.25s, opacity 0.25s;
  -webkit-transition: opacity 0.25s, -webkit-transform 0.25s;
  transition: opacity 0.25s, -webkit-transform 0.25s;
  -o-transition: transform 0.25s, opacity 0.25s;
  transition: transform 0.25s, opacity 0.25s;
  transition: transform 0.25s, opacity 0.25s, -webkit-transform 0.25s;

  -webkit-transition-delay: inherit;
  -o-transition-delay: inherit;
  transition-delay: inherit;
}

.angles-menu-item-content i {
  -webkit-transition: all .25s cubic-bezier(.160, .560, .170, .999);
  -o-transition: all .25s cubic-bezier(.160, .560, .170, .999);
  transition: all .25s cubic-bezier(.160, .560, .170, .999);
}

.angles-container .m--btn .angles-menu-item-content {
  opacity: 0;
}

.angles-container .angles-menu-item:nth-child(2) .angles-menu-item-content,
.angles-container .angles-menu-item:nth-child(4) .angles-menu-item-content,
.angles-container .angles-menu-item:nth-child(6) .angles-menu-item-content {
  left: 50px;
  margin-top: 65px;
}

.angles-container .angles-menu-item.js-menuBtn.m--btn:nth-child(2) .angles-menu-item-content,
.angles-container .angles-menu-item.js-menuBtn.m--btn:nth-child(4) .angles-menu-item-content,
.angles-container .angles-menu-item.js-menuBtn.m--btn:nth-child(6) .angles-menu-item-content {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);

}

.angles-container .angles-menu-item.js-menuBtn.m--btn:nth-child(2),
.angles-container .angles-menu-item.js-menuBtn.m--btn:nth-child(4),
.angles-container .angles-menu-item.js-menuBtn.m--btn:nth-child(6) {
  top: 11px;

  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);

  -webkit-filter: FlipH;

  filter: FlipH;
  -ms-filter: "FlipH";
}

.angles-container .angles-menu-item.m--btn {
  z-index: 5;
  position: absolute;
  left: -10px;
  top: 3px;
  width: 185px;
  height: 0;
  opacity: 1;

  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.angles-container .angles-menu-item.m--btn:after {
  opacity: 1;
}

.angles-container .angles-wrapper_page {
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 50px 0 0 50px;
  opacity: 0;

  -webkit-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  transform-origin: 100% 50%;

  -webkit-transition: all 0.35s 0.35s cubic-bezier(.160, .560, .170, .999), opacity 0.35s 0s cubic-bezier(.160, .560, .170, .999);
  -o-transition: all 0.35s 0.35s cubic-bezier(.160, .560, .170, .999), opacity 0.35s 0s cubic-bezier(.160, .560, .170, .999);
  transition: all 0.35s 0.35s cubic-bezier(.160, .560, .170, .999), opacity 0.35s 0s cubic-bezier(.160, .560, .170, .999);

  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  will-change: transform, opacity, filter;
}

.angles-container .angles-wrapper_page.active {
  z-index: 1;
  opacity: 1;
}

.angles-container .main-content {
  -webkit-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  transform-origin: 100% 50%;

  -webkit-transition: all 0.35s 0.35s cubic-bezier(.160, .560, .170, .999), opacity 0.35s 0s cubic-bezier(.160, .560, .170, .999);
  -o-transition: all 0.35s 0.35s cubic-bezier(.160, .560, .170, .999), opacity 0.35s 0s cubic-bezier(.160, .560, .170, .999);
  transition: all 0.35s 0.35s cubic-bezier(.160, .560, .170, .999), opacity 0.35s 0s cubic-bezier(.160, .560, .170, .999);

  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  will-change: transform, opacity, filter;
}

.angles-container .main-content.menu-active {
  -webkit-transform: scale(0.7) translate3d(30%, 0, 0);
  -ms-transform: scale(0.7) translate3d(30%, 0, 0);
  transform: scale(0.7) translate3d(30%, 0, 0);

  -webkit-transition: all 0.5s cubic-bezier(.160, .560, .170, .999);
  -o-transition: all 0.5s cubic-bezier(.160, .560, .170, .999);
  transition: all 0.5s cubic-bezier(.160, .560, .170, .999);
}

.angles-container .angles-wrapper_page-heading {
  color: #fff;
  display: inline-block;
  font-size: 39px;
  font-weight: 700;
  padding-bottom: 7px;
  text-transform: uppercase;
}

.angles-container .angles-heading {
  color: #f4851f;
  text-transform: uppercase;
}

.angles-container .angles-wrapper_page-subheading {
  display: block;

  font-size: 16px;
  color: #ffffff;
  letter-spacing: .3px;
  text-transform: uppercase;
}

.download-btn {
  border: 4px solid #7e878f;
  color: #7e878f;

  font-size: 25px;
  font-weight: 700;
  float: right;
  margin: 3px 0 0 15px;
  padding: 9px 12px;
  position: relative;
  text-transform: uppercase;
}

.download-btn .btn-hover {
  background: #7e878f;
  color: #313332;
  position: absolute;
  width: 100%;
  height: 100%;
  line-height: 50px;
  top: 0;
  left: 0;
  opacity: 0;

  -webkit-transition: all 0.25s cubic-bezier(.160, .560, .170, .999);
  -o-transition: all 0.25s cubic-bezier(.160, .560, .170, .999);
  transition: all 0.25s cubic-bezier(.160, .560, .170, .999);
}

.download-btn:hover .btn-hover {
  opacity: 1;
}

.back-home {
  display: inline-block;

  font-size: 14px;
  margin-top: 20px;
  text-transform: uppercase;
}

.back-home .btn {
  border: 2px solid #7e878f;
  color: #7e878f;
}

.back-home .btn:hover {
  border-color: #f4851f;
  color: #f4851f;
}

.angles-container .angles-wrapper_page-link {
  display: block;
  font-size: 25.6px;
  color: #0299DC;
  margin-bottom: 8px;
  text-decoration: none;
}

.angles-container .angles-page-content {
  background: #eee;
  position: absolute;
  left: 0;
  bottom: 1.6px;
  width: 100%;
  top: 0px;
  z-index: -1;
}

.angles-container #home .angles-content {
  /* max-width: inherit; */
}

.angles-content .container {}

.angles-container .angles-content,
.single-post .angles-container .angles-wrapper_page-1.active .angles-content,
.single-portfolio .angles-container .angles-wrapper_page-1.active .angles-content {
  background: #ffffff;
  width: 100%;
  height: 100% !important;
  overflow-y: scroll;
  overflow-x: hidden;
}

.header-top {
  z-index: 99999;
  position: absolute;
  top: 50px;
  left: 50px;
}

.separator {
  left: -2px;
  top: -6px;
  position: absolute;
  z-index: 2;
}

.separator svg {
  fill: #313332;
  height: 20px;
  width: 100%;
  stroke-width: 0;
  overflow: visible;
}

.separator svg:not(:root) {
  overflow: visible;
}

.bottom-separator {
  right: -70px;
  bottom: 60px;
  position: fixed;
  z-index: 1;
  width: 300px;
}

.bottom-separator svg {
  fill: #eee;
  stroke-width: 0;
  width: 100%;
}

.icon-pattern {
  height: 10px;
  width: 10px;
  overflow: visible !important;
  -webkit-transition: all .25s cubic-bezier(.160, .560, .170, .999);
  -o-transition: all .25s cubic-bezier(.160, .560, .170, .999);
  transition: all .25s cubic-bezier(.160, .560, .170, .999);
}

.angles-container {
  overflow: -moz-scrollbars-none;
}

.angles-container ::-webkit-scrollbar {
  display: none;
}

::-moz-scrollbar {
  display: none;
  width: 0;
  visibility: hidden !important;
}

.not-scroll-body ::-moz-scrollbars {
  display: none;
  width: 0;
  visibility: hidden !important;
}

.angles-container ::-ms-scrollbar {
  display: none;
}

.angles-container ::-o-scrollbar {
  display: none;
}

.top-space {
  height: 10%;
}

/* 4 --------- angles Slider ---------*/

#home .angles-content {
  padding: 0px;
  /* overflow: inherit; */
}

.sp-slide {
  text-align: right;
}

.sp-slide.slide-1 {
  background: url(../img/slider/1.jpg) top center no-repeat;
  background-size: cover;
}

.sp-slide.slide-2 {
  background: url(../img/slider/2.jpg) top center no-repeat;
  background-size: cover;
}

.sp-slide.slide-3 {
  background: url(../img/slider/3.jpg) top center no-repeat;
  background-size: cover;
}

.angles-container,
.main-content,
.angles-content,
.angles-slider,
.sp-slides-container,
.sp-mask.sp-grab,
.sp-slides,
.sp-slide,
.sp-image-container {
  height: 100vh !important;
}

.sp-image {
  background-repeat: no-repeat;
  background-position: center center;
  height: 95vh !important;
  margin-top: 0px !important;
  background-size: cover;
}

.angles-slider .main-title,
.angles-slider .sub-title {
  color: #ffffff;
  letter-spacing: 1px;
  /* text-transform: uppercase; */
}

.angles-slider .main-title {
  font-size: 46px;
  font-family: "Montserrat", 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: 800;
}

.angles-slider .sub-title {
  font-size: 20px;
  font-family: "Montserrat", 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.sp-slide span {
  display: block;
}

.sp-layer.sp-black {
  background: transparent !important;
  padding: 0 !important;
  top: -9% !important
}

.sp-layer.sp-black .sub-title {
  padding-top: 10px;
}

.slide-5 .sp-layer.sp-black {
  text-align: center;
}

.slider-btn span {
  display: inline-block;
}

.slider-btn a {
  background-color: #313332;
  color: #ffffff;
  display: inline-block;
  font-family: "Montserrat", 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 15px;
  padding: 20px 40px;
  text-transform: uppercase;
}

.slider-btn .btn-icon {
  padding-left: 10px;
}

.slide-2 .slider-btn a {
  background: transparent;
  border: 2px solid white;
}

.slide-3 .slider-btn a,
.slide-5 .slider-btn a {
  background-color: #272626;
}

.slider-btn a:hover {
  color: #f4851f !important;
}

.slide-3 .slider-btn a:hover,
.slide-5 .slider-btn a:hover {
  color: #313332 !important;
}

.slide-5 .sp-layer.slider-btn {
  text-align: center;
}

.slider-social a {
  background: rgba(55, 71, 79, 0.3);
  color: #ffffff;
  display: inline-block;
  font-size: 22px;
  height: 45px;
  line-height: 45px;
  width: 45px;
  text-align: center;
}

/* 5 ------- Profile Section -------*/
.profile-details .description {
  padding-bottom: 47px;
}

.profile-meta {
  padding: 0 0 30px;
}

.profile-meta,
.profile-meta a {
  color: #7e878f;

  font-size: 17px;
}

.profile-meta .meta-icon {
  font-weight: 700;
  float: left;
  width: 120px;
  position: relative;
}

.profile-meta .meta-icon:before {
  content: ':';
  position: absolute;
  right: 0;
  top: 0;
}

.profile-meta span {
  padding-left: 10px;
}

.profile-meta .media-body {
  padding-left: 10px;
}

.signature {
  color: #2c3e50;
  font-size: 50px;
  padding-top: 40px;
}

.profile-meta .load-more {
  color: #ffffff;
  font-size: 15px;
  margin-top: 35px;
  padding: 20px 30px;
}

.profile-meta .btn-icon {
  padding: 0 15px 0 0;
}

.profile-meta .load-more:hover {
  color: #f4851f;
}

.interest-list {
  display: flex;
  flex-flow: row wrap;
}

.interest-list .col-sm-2.col-xs-6 {
  padding: 0 2px;
}

.interest-list .item {
  background: #EAE5E3;
  color: #787879;
  display: inline-block;
  min-height: 160px;
  width: 100%;
  height: 100%;
  padding: 15px;
  position: relative;
  text-align: center;
}

.interest-list .item-icon {
  color: #7e878f;
  display: block;
  padding: 0 0 10px 0;
  font-size: 1rem;
  line-height: 1;
  -webkit-transition: all .25s cubic-bezier(.160, .560, .170, .999);
  -o-transition: all .25s cubic-bezier(.160, .560, .170, .999);
  transition: all .25s cubic-bezier(.160, .560, .170, .999);
}

.interest-list .item-title {
  color: #2b2d2e;
  font-size: 15px;
  font-weight: normal;
  padding-top: 12px;
  text-transform: uppercase;
  -webkit-transition: all .25s cubic-bezier(.160, .560, .170, .999);
  -o-transition: all .25s cubic-bezier(.160, .560, .170, .999);
  transition: all .25s cubic-bezier(.160, .560, .170, .999);
}

.interest-list .item:hover {
  background: #7e878f;
  z-index: 1;
}

.interest-list .item:before {
  content: '';
  position: absolute;
  top: -20px;
  left: -65px;
  height: 125%;
  width: 180%;
  background: #7e878f;
  opacity: 0;
  z-index: -1;

  -webkit-transition: all .25s cubic-bezier(.160, .560, .170, .999);
  -o-transition: all .25s cubic-bezier(.160, .560, .170, .999);
  transition: all .25s cubic-bezier(.160, .560, .170, .999);
}

.interest-list .item:hover:before {
  opacity: 1;
}

.interest-list .item:hover .item-icon {
  color: #ffffff;
  font-size: 125px;
  padding: 0;
  opacity: 0.25;
}

.interest-list .item-details {
  -webkit-transition: all .25s cubic-bezier(.160, .560, .170, .999);
  -o-transition: all .25s cubic-bezier(.160, .560, .170, .999);
  transition: all .25s cubic-bezier(.160, .560, .170, .999);
}

.interest-list .item:hover .item-details {
  position: absolute;
  top: 0;
  left: -40px;
  text-align: left;
  z-index: 2;
  height: 170px;
  width: 230px;
}

.interest-list .item:hover .item-title,
.interest-list .item:hover .item-description {
  color: #ffffff;
  display: block;
  z-index: 2;
}

.interest-list .item-description {
  display: none;
  font-size: 16px;
  margin-top: -15px;
  -webkit-transition: all .25s cubic-bezier(.160, .560, .170, .999);
  -o-transition: all .25s cubic-bezier(.160, .560, .170, .999);
  transition: all .25s cubic-bezier(.160, .560, .170, .999);
}

.client-testimonial {
  border: 2px solid #eee;
  margin-top: 10px;
  position: relative;
}

.quote-icon {
  background: #eee;
  border-radius: 50%;
  color: #313332;
  display: inline-block;
  font-size: 25px;
  height: 64px;
  width: 64px;
  line-height: 64px;
  left: 50%;
  margin-left: -32px;
  top: -32px;
  position: absolute;
}

.testimonial-slider .item {
  padding: 55px 60px 60px 60px;
}

.client-details {
  color: #7e878f;

  padding-bottom: 20px;
}

.client-details span.client-name {
  display: block;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}

.designation {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  padding-top: 10px;
}

.designation a {
  color: #7e878f;
}

.client-speech {
  color: #7e878f;

  font-size: 16px;
  font-style: italic;
  line-height: 27px;
}

.testimonial-slider p {
  font-style: italic;
  font-size: 1.8rem;
}

/* 5 ------- Resume Section -------*/

.section-title {
  padding-bottom: 57px;
}

.job-details,
.education-details {
  position: relative;
}

.job-details:before,
.job-details:after,
.education-details:before,
.education-details:after {
  content: '';
  position: absolute;
  background: #eee;
  left: 50%;
}

.job-details:before,
.education-details:before {
  width: 2px;
  height: 100%;
  margin-left: -1px;
  top: 0;
}

.job-details:after,
.education-details:after {
  width: 20px;
  height: 20px;
  margin-left: -10px;
  bottom: 0;
}

.about-resume .item {
  clear: both;
  display: inline-block;
  padding-bottom: 40px;
}

.about-resume .item .box {
  background: #eee;
  color: #3f3e3e;
  font-family: "Montserrat", 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  float: right;
  padding: 24px 30px;
  position: relative;
  text-align: right;
}

.job-details .item:nth-child(even) .box,
.education-details .item:nth-child(even) .box {
  float: left;
  text-align: left;
}

.job-details .item:nth-child(odd) .box:before,
.job-details .item:nth-child(odd) .box:after,
.job-details .item:nth-child(even) .box:before,
.job-details .item:nth-child(even) .box:after,
.education-details .item:nth-child(odd) .box:before,
.education-details .item:nth-child(odd) .box:after,
.education-details .item:nth-child(even) .box:before,
.education-details .item:nth-child(even) .box:after {
  content: '';
  position: absolute;
  background: #eee;
  top: 50%;
}

.job-details .item:nth-child(odd) .box:before,
.education-details .item:nth-child(odd) .box:before {
  width: 35px;
  height: 2px;
  margin-top: -1px;
  right: -30px;
}

.job-details .item:nth-child(even) .box:before,
.education-details .item:nth-child(even) .box:before {
  width: 35px;
  height: 2px;
  margin-top: -1px;
  left: -30px;
}

.job-details .item:nth-child(odd) .box:after,
.education-details .item:nth-child(odd) .box:after {
  width: 10px;
  height: 10px;
  right: -35px;
  margin-top: -5px;
}

.job-details .item:nth-child(even) .box:after,
.education-details .item:nth-child(even) .box:after {
  width: 10px;
  height: 10px;
  left: -35px;
  margin-top: -5px;
}

.about-resume .item .box time {
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
}

.institution {
  font-size: 18px;
  font-weight: 400;
  padding-top: 9px;
  text-transform: capitalize;
}

.about-resume .name,
.about-resume .title {
  color: #7e878f;

  font-size: 22px;
  font-weight: 400;
  padding-bottom: 10px;
  text-transform: capitalize;
}

.job-details .item .col-sm-6:nth-child(odd),
.education-details .item .col-sm-6:nth-child(odd) {
  padding-right: 30px;
}

.job-details .item .col-sm-6:nth-child(even),
.education-details .item .col-sm-6:nth-child(even) {
  padding-left: 30px;
}

.job-details .item:nth-child(even) .col-sm-6:first-child,
.education-details .item:nth-child(even) .col-sm-6:first-child {
  padding-left: 30px;
  text-align: left;

  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}

.job-details .item:nth-child(even) .col-sm-6:nth-child(2),
.education-details .item:nth-child(even) .col-sm-6:nth-child(2) {
  padding-right: 30px;
  text-align: right;

  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.facts {
  border-color: #7e878f;
}


.facts .col-md-3.col-sm-6 {
  border: 2px solid #eee;
  border-right: 0;
  padding: 0;

}

.facts .col-md-3.col-sm-6:hover {
  background: #7e878f;
  border-color: #7e878f;
}

.facts .items {
  padding: 55px;
}

.facts .col-md-3.col-sm-6:last-child {
  border-right: 2px solid #eee;
}

.facts .item-icon {
  color: #7e878f;
  font-size: 65px;
  padding-bottom: 12px;

}

.facts .col-md-3.col-sm-6:hover .item-icon,
.facts .col-md-3.col-sm-6:hover .count-number,
.facts .col-md-3.col-sm-6:hover .item-about {
  color: #fff;
}

.facts .count-number {
  color: #2c3035;
  display: block;

  font-size: 25px;
  font-weight: 700;
  padding-bottom: 12px;
}

.facts .item-about {
  color: #7e878f;

  font-size: 14px;
  text-transform: uppercase;
}

.facts-items {
  margin: 0;
}

.facts-items,
.facts .col-md-3.col-sm-6,
.facts .item-icon,
.facts .count-number,
.facts .item-about {
  -webkit-transition: all .25s cubic-bezier(.160, .560, .170, .999);
  -o-transition: all .25s cubic-bezier(.160, .560, .170, .999);
  transition: all .25s cubic-bezier(.160, .560, .170, .999);
}

.about-resume .title {
  padding-bottom: 43px;
}

.skill-details .progress {
  background: #eee;
  -webkit-box-shadow: none;
  box-shadow: none;
  height: 4px;
  margin-bottom: 55px;
  overflow: visible;
  position: relative;
}

.skill-details .progress-bar {
  background: #6e707d;
  position: absolute;
  top: 0;
  left: 0;
  text-align: left;
}

.skill-details .progress span {
  display: inline-block;

  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
}

.progress .bar {
  -webkit-transition: width 2s cubic-bezier(.160, .560, .170, .999);
  -o-transition: width 2s cubic-bezier(.160, .560, .170, .999);
  transition: width 2s cubic-bezier(.160, .560, .170, .999);
}

.progress .progress-title {
  color: #7e878f;
  display: inline-block;
  padding-top: 17px;
  position: relative;
}

.progress .percent {
  color: #a6aac0;
  padding-left: 10px;
  position: relative;
}

.progress .percent:before {
  content: '/';
  color: #7e878f;
  position: absolute;
  top: 0;
  left: 1px;
}

.progress-wrap {
  display: inline-block;
}

.language-details .chart {
  position: relative;
}

.chart-content {
  bottom: -53px;
  position: absolute;
  text-align: center;
  width: 100%;
}

.language-details .item-title {
  color: #7e878f;
  display: inline-block;

  font-size: 14px;
  font-weight: 400;
  display: inline-block;
  position: relative;
  text-transform: uppercase;
}

.chart-content .item-title:before {
  content: '/';
  position: absolute;
  right: -7px;
  top: 0;
  color: #7e878f;
}

.chart-content .data-percent {
  color: #a6aac0;
  padding-left: 5px;
  position: relative;
}

.chart-content .data-percent:after {
  content: '%';
  right: -12px;
  color: #a6aac0;
  position: absolute;
  top: 2px;
}

.knowledge-details span {
  color: #7e878f;
  display: block;

  font-size: 14px;
  padding: 0 0 20px 17px;
  position: relative;
  text-transform: uppercase;
}

.knowledge-details span:before {
  content: "\f068";
  font-family: 'Fontawesome';
  position: absolute;
  top: 1px;
  left: 0;
  color: #7e878f;
  font-size: 13px;
}

/* 6 ------ Services Section ------*/
.service-item {
  color: #ffffff;
  margin: 0 10px 0 2px;
  padding: 0 13px;
  width: 32%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
}

.service-item:before {
  content: '';
  height: 0;
  width: 0;
  left: 0;
  top: 0;
  border-top: 175px solid #ffffff;
  border-right: 375px solid transparent;
  position: absolute;
}

.service-item.item-1 {
  background-color: #e15418;
}

.service-item.item-2 {
  background-color: #e5601a;
}

.service-item.item-3 {
  background-color: #e77313;
}

.service-item.item-4 {
  background-color: #ea840c;
}

.service-item.item-5 {
  background-color: #ed9106;

}

.service-item.item-6 {
  background-color: #f0a000;
}

.progress-icon {
  font-size: 55px;
  padding-top: 150px;
}

.progress-title {

  font-size: 25px;
  font-weight: 700;
  padding-top: 35px;
  text-transform: uppercase;
}

.progress-description p {
  color: #ffffff;

  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  padding: 18px 15px 52px 15px;
}

.our-services {
  padding-top: 35px;
}

#services .bottom-padding {
  padding-top: 90px;
}

#clients .bottom-padding {
  padding-top: 40px;
}

.pricing-tables .col-md-4.col-sm-6 {
  border: 2px solid #eee;
  margin: 0 0 30px;
  padding: 0;
}

.pricing-tables .col-md-4.col-sm-6:first-child,
.pricing-tables .col-md-4.col-sm-6:nth-child(2) {
  border-right: none;
}

.table {
  margin: 0;
}

.table-title {
  border-bottom: 2px solid #eee;
  color: #7e878f;

  font-size: 18px;
  font-weight: 700;
  padding: 33px 0;
  text-transform: uppercase;
}

.currency,
.price-money {
  color: #313332;

  font-size: 40px;
  font-weight: 700;
}

.pricing-cost {
  border-bottom: 2px solid #eee;
  padding: 29px 0;
}

.duration {
  color: #a6aac0;

  font-size: 14px;
  font-weight: normal;
  padding-left: 10px;
  position: relative;
  text-transform: uppercase;
}

.duration:before {
  content: '/';
  position: absolute;
  top: 3px;
  left: 0;
}

.pricing-tables .col-md-4.col-sm-6:nth-child(2) {
  border-top-color: #f9cc20;
}

.pricing-tables .col-md-4.col-sm-6:hover {
  border-top-color: #f9cc20;
}

.pricing-tables .col-md-4.col-sm-6:nth-child(2) .table-title,
.pricing-tables .col-md-4.col-sm-6:nth-child(2) .pricing-cost,
.pricing-tables .table:hover .table-title,
.pricing-tables .table:hover .pricing-cost {
  background: #f4851f;
  border-color: #f9cc20;
  color: #ffffff;
}

.pricing-tables .col-md-4.col-sm-6:nth-child(2) .currency,
.pricing-tables .col-md-4.col-sm-6:nth-child(2) .price-money,
.pricing-tables .col-md-4.col-sm-6:nth-child(2) .duration {
  color: #ffffff;
}

.table-title,
.pricing-cost,
.currency,
.price-money,
.duration {
  -webkit-transition: all .25s cubic-bezier(.160, .560, .170, .999);
  -o-transition: all .25s cubic-bezier(.160, .560, .170, .999);
  transition: all .25s cubic-bezier(.160, .560, .170, .999);
}

.table:hover .table-title,
.table:hover .pricing-cost,
.table:hover .currency,
.table:hover .price-money,
.table:hover .duration {
  color: #ffffff;
}

.table-details {
  padding: 24px 0;
}

.table-details p {
  display: block;
  font-size: 16px;
  line-height: 34px;
}

.table-bottom {
  border-top: 2px solid #eee;
}

.table-bottom .btn {
  color: #91989f;
  display: block;

  font-size: 18px;
  font-weight: 700;
  padding: 27px 0;
  text-transform: uppercase;
}

.table-bottom .btn:hover {
  color: #f9cc20;
}

.testimonial .item {
  margin-bottom: 43px;
}

.testimonial .client-name {
  display: inline-block;
}

.testimonial .client-name a {
  color: #7e878f;

  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}

.company {
  color: #7e878f;
  font-size: 14px;
  font-weight: normal;
}

.testimonial .client-speech {
  position: relative;
  z-index: 1;
}

.testimonial .client-speech:before {
  content: "\f10d";
  color: #f2f2f2;
  font-family: 'Fontawesome';
  font-size: 85px;
  font-style: normal;
  position: absolute;
  left: 0;
  top: -15px;
  z-index: -1;
}

.testimonial .client-speech span {
  display: inline-block;
  position: relative;
  width: 40px;
}

.testimonial .client-speech span:before {
  content: '';
  width: 35px;
  height: 2px;
  background: #b0bec5;
  position: absolute;
  top: 0;
  margin-top: -6px;
  left: 0;
}

.logo-slider {
  clear: both;
  margin-top: 50px;
  margin-bottom: 50px;
}

.logo-slider img {
  opacity: 0.5;

  -webkit-transition: all 0.25s cubic-bezier(.160, .560, .170, .999);
  -o-transition: all 0.25s cubic-bezier(.160, .560, .170, .999);
  transition: all 0.25s cubic-bezier(.160, .560, .170, .999);
}

.logo-slider .item:hover img {
  opacity: 1;
}

.owl-buttons {
  position: absolute;
  top: 50%;
  width: 100%;
}

.owl-prev,
.owl-next {
  background: #eee;
  color: #a6a6a6;
  font-size: 18px;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  position: absolute;
  top: 50%;
  margin-top: -20px;
}

.owl-prev {
  left: 0;
}

.owl-next {
  right: 0;
}

/* 7 ------- Works Section -------*/

.more-items,
.more-items .item {
  padding-top: 80px;
}

.more-items .item-details {
  padding-left: 25px;
}

.isotope .isotope-item {
  border: 2px solid #ffffff;

  -webkit-transition-duration: 0.8s;
  -o-transition-duration: 0.8s;
  transition-duration: 0.8s;

  -webkit-transition-property: -webkit-transform, opacity;
  -o-transition-property: -o-transform, opacity;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  -o-transition-property: transform, opacity;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
}

.itemFilter {
  clear: both;
  margin: auto;
  padding: 5px 0px 45px 0px;
  width: auto;
}

.itemFilter a {
  color: #7e878f;
  display: inline-block;

  font-size: 16px;
  font-weight: 700;
  margin: 0 15px 15px 7px;
  padding: 7px 0;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
}

.itemFilter a:hover {
  color: #343434;
}

.itemFilter a.current {
  color: #313332 !important;
}

.itemFilter a.current:before {
  content: '';
  position: absolute;
  background: #7e878f;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
}

.work-items {
  margin-top: 35px;
}

.work-items .item,
.related-projects .item {
  position: relative;
}

.image-popup-vertical-fit:before {
  content: '';
  background: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 101%;
  top: 0px;
  left: 0px;
  position: absolute;
  opacity: 0;
  -webkit-transition: .35s cubic-bezier(.160, .560, .170, .999);
  -o-transition: .35s cubic-bezier(.160, .560, .170, .999);
  transition: .35s cubic-bezier(.160, .560, .170, .999);
}

.item:hover .image-popup-vertical-fit:before {
  opacity: 1;
}

.work-items .item-details,
.related-projects .item-details {
  width: 100%;
  position: absolute;
  top: 75%;
  left: 0px;
  margin-top: -10px;
  opacity: 0;
  -webkit-perspective: 800px;
  perspective: 800px;

  -webkit-transition: .4s cubic-bezier(.160, .560, .170, .999);
  -o-transition: .4s cubic-bezier(.160, .560, .170, .999);
  transition: .4s cubic-bezier(.160, .560, .170, .999);
}

.work-items .item:hover .item-details,
.related-projects .item:hover .item-details {
  top: 50%;
  opacity: 1;
}

.work-items .item-title,
.related-projects .item-title {
  font-family: 'Open Sans';
  font-size: 17px;
  font-weight: normal;
}

.work-items .item-title a,
.related-projects .item-title a {
  color: #ffffff;
}

/* 8 -------- Blog Section --------*/
.blog-posts article {
  margin-bottom: 40px;
}

.post-content {
  border: 2px solid #eee;
  padding: 30px;
}

.entry-title {
  color: #313332;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}

.entry-title a {
  color: #313332;
}

.entry-title a:hover {
  color: #f4851f;
}

.meta-list li,
.post-meta {
  color: #bebebe;

  font-size: 14px;
  padding-right: 10px;
  position: relative;
  text-transform: uppercase;
}

.meta-list li:before {
  content: '';
  background: #bebebe;
  height: 100%;
  width: 1px;
  top: 0px;
  right: 3px;
  position: absolute;
}

.meta-list li:last-child:before {
  content: '';
  display: none;
}

.meta-list a {
  color: #bebebe;
}

.entry-content {
  color: #7e878f;

  font-size: 16px;
  line-height: 26px;
  padding: 20px 0 0;
}

.attachmentlink {
  background: #00b8d4;
  color: #ffffff;

  font-size: 23px;
  font-weight: 700;
  padding: 40px 40px;
  position: relative;
  z-index: 0;
}

.attachmentlink:before {
  content: "\f0c6";
  color: #ffffff;
  font-family: 'FontAwesome';
  font-weight: normal;
  font-size: 64px;
  top: -25px;
  right: 20px;
  opacity: 0.2;
  position: absolute;
}

.attachmentlink a {
  color: #ffffff;
  display: block;

  font-size: 15px;
  font-weight: 300;
  padding-top: 12px;
}

.carousel-control {
  background-image: none !important;
  background-color: rgba(0, 0, 0, 0.75);
  width: 50px;
  height: 50px;
  line-height: 50px;
  top: 50%;
  margin-top: -25px;
  text-align: center;
}

.blog-posts .post-blockquote,
.blog-page .post-blockquote {
  background: #f15b5d;
  border: none;
  color: #ffffff;

  font-size: 36px;
  font-weight: 700;
  line-height: 50px;
  margin: 0px;
  padding: 33px 40px;
  position: relative;
  word-spacing: 1px;
  z-index: 0;
}

.blog-posts .post-blockquote:before,
.blog-page .post-blockquote:before {
  content: "\f10d";
  color: #ffffff;
  font-family: 'FontAwesome';
  font-size: 96px;
  position: absolute;
  top: 30px;
  left: 20px;
  z-index: -1;
  opacity: 0.2;
}

.blog-posts .quote-author,
.blog-page .quote-author {
  color: #fccccc;
  display: block;

  font-size: 14px;
  font-weight: 700;
  padding-top: 30px;
  text-transform: uppercase;
}

/* 9 ------- Clients Section -------*/
/* 10 ------- Contact Section --------*/
#contact .angles-content,
#contact .content-bottom {
  padding-bottom: 0;
}

#contact .bottom-padding {
  padding: 0 0;
}

.contact-info-box {
  border-left: 2px solid #eee;
  margin-bottom: 30px;
}

.contact-info-box:first-child {
  border-left: 0;
}

#contact .item-icon {
  font-size: 44px;
}

#contact .item-title {
  font-weight: 700;
  padding-top: 20px;
}

#contact .texts {
  color: #7e878f;

  font-size: 16px;
  line-height: 25px;
}

#contact .texts a {
  color: #7e878f;
}

.wpcf7-form p {
  display: block;
  position: relative;
}

.wpcf7-form-control {
  border: 2px solid #eee;

  font-size: 15px;
  margin-bottom: 15px;
  padding: 15px 15px;
  width: 100%;
}

.wpcf7-form-control:focus,
.wpcf7-form-control:active {
  outline: 0;
}

.wpcf7-submit {
  background: #f4851f;
  border-color: #f4851f;
  color: #ffffff;

  font-size: 15px;
  font-weight: 700;
  /* max-width: 235px; */
  max-height: 65px;
  text-transform: uppercase;
}

.wpcf7-submit:hover {
  background: #fff;
  color: #f4851f;
}

#contact-loading {
  display: none;
  cursor: inherit;
  line-height: 110px;
}

#contact-loading:hover {
  color: inherit;
}

.contact-message {
  text-align: center;
  font-size: 1.125em;
}

.wpcf7-response-output {
  color: green;
  font-size: 16px;
}

.wpcf7-response-output div {
  display: inline-block;
}

.google-map {
  margin: 50px 0 0 0;
  height: 375px;
}

/* .google-map-container {
  width: 100%;
  height: 375px;
} */

/* 11 --------- Blog Single page ---------*/
.single-post-wrapper .post-content {
  border: none;
  padding: 50px 0;
}

.single-post-wrapper .entry-title {
  font-size: 30px !important;
  padding-top: 35px;
}

.single-post-wrapper .meta-list {
  color: #c0c0c0;
  padding-top: 6px;
}

.single-post-wrapper .meta-list li,
.single-post-wrapper .meta-list a {
  color: #c0c0c0;
}

.single-post-wrapper .entry-content p {
  line-height: 28px;
}

.text-blog {
  margin: 0 0 0 100px
}

.single-post-wrapper blockquote {
  background: #ffffff;
  border-left: none;
  color: #313332;

  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  line-height: 36px;
  margin: 35px 0px 32px 0px;
  padding: 25px 40px 35px 75px;
  z-index: 1;
  position: relative;
}

.single-post-wrapper blockquote:before {
  content: "\f10d";
  font-family: 'FontAwesome';
  position: absolute;
  font-size: 75px;
  font-style: normal;
  color: #e4e4e4;
  top: 5px;
  left: 45px;
  z-index: -1;
}

.submited-comment {
  padding-top: 35px;
}

.comment-section {
  padding-top: 35px;
  padding-bottom: 10px;
}

.commentlist .comment article {
  padding-bottom: 52px;
}

.commentlist .children {
  padding-left: 60px;
}

.commentlist img {
  max-width: inherit;
}

.comment-content {
  padding-left: 22px;
}

.commentlist .comment-meta {
  padding-bottom: 21px;
}

.commentlist .comment-author,
.commentlist .published-time {
  display: inline-block;
}

.commentlist .author-name {
  color: #313332;

  font-size: 22px;
  font-weight: 800;
  text-transform: uppercase;
}

.commentlist .published-time {
  color: #bfbfbf;
  display: block;

  font-size: 14px;
  padding-top: 6px;
}

.comment-description {
  color: #7e878f;

  line-height: 27px;
}

.comment-reply {
  padding-top: 20px;
}

.comment-reply .reply-btn {
  color: #f4851f;
  font-size: 14px;
  font-weight: 700;
  text-transform: capitalize;
  padding: 0px;
  position: relative;
}

.comment-reply .reply-btn:before {
  content: "\f064";
  font-family: 'FontAwesome';
  font-size: 14px;
  color: #f4851f;
  top: 5px;
  right: -20px;
  position: absolute;
}

.leave-comment {
  padding-top: 25px;
}

.comment-form {
  padding-top: 40px;
  padding-bottom: 25px;
}

.comment-form input,
.comment-form input:focus,
.comment-form textarea,
.comment-form textarea:focus {
  outline: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.comment-form input {
  max-width: 75%;
}

.comment-form .form-control {
  border: 2px solid #eee;
  border-radius: 0px;
  color: #7e878f;
  font-size: 16px;
  margin-bottom: 20px;
  padding: 25px 10px;
  text-indent: 5px;
}

.comment-form .submit-btn {
  background: #f4851f;
  border: 1px solid #f4851f;
  color: #ffffff;

  font-size: 19px;
  font-weight: 800;
  padding: 15px 48px;
  text-transform: uppercase;
}

.comment-form .submit-btn:hover {
  background: #ffffff;
  border-color: #f4851f;
  color: #f4851f;
}

/* 12 ------- Single Portfolio Page --------*/

.single-portfolio .entry-title {
  display: inline-block;
  font-size: 30px;
}

.window-location {
  display: inline-block;
}

.window-location a {
  color: #333333;
  font-size: 24px;
}

.post-close {
  padding: 0 15px;
}

.project-thumbnail {
  padding-top: 22px;
}

.single-portfolio .project-details {
  padding-top: 55px;
}

.project-details .title,
.submited-comment .title,
.leave-comment .title {
  color: #313332;

  font-size: 30px;
  font-weight: 800;
  padding-bottom: 25px;
  text-transform: uppercase;
}

.project-details .title {
  font-size: 25px;
}

.project-details .col-sm-4 {
  padding-left: 45px;
}

.project-meta,
.project-meta a,
.project-meta time {
  color: #7e878f;

  font-weight: 700;
  font-size: 16px;
  text-transform: capitalize;
}

.project-meta li {
  display: block;
  padding-bottom: 17px;
}

.project-meta li:before {
  font-family: 'themify';
}

.project-social {
  padding-top: 12px;
}

.project-social a {
  color: #7e878f;
  display: inline-block;
  font-size: 18px;
  width: 20px;
  height: 25px;
}

.project-details .btn-container {
  padding-top: 30px;
}

.project-details .btn {
  background-color: #f4851f;
  border: 1px solid #f4851f;
  color: #ffffff;
  font-weight: 800;
  font-size: 15px;
  padding: 15px 27px;
  text-transform: uppercase;
}

.project-details .btn:hover {
  background-color: transparent;
  color: #f4851f;
}

.related-projects {
  padding-top: 100px;
}

.related-title {
  color: #313332;

  font-size: 25px;
  font-weight: 700;
  text-transform: uppercase;
}

.projects {
  padding-top: 55px;
}

/* 13 ----- Pagination -------*/

.pagination {
  clear: both;
  display: block;
  text-align: left;
  padding: 60px 0 0;
  margin: 0;
}

.pagination li {
  display: inline-block;
}

.pagination>li>a {
  background: #eee;
  border-color: #eee;
  border-radius: 0;
  border-radius: 0;
  color: #7e878f;

  font-size: 16px;
  height: 50px;
  width: 50px;
  line-height: 40px;
  text-align: center;
}

.pagination>li:last-child>a {
  border-radius: 0;
}

.pagination>li.active>a,
.pagination>li.active>a:hover,
.pagination>li>a:hover {
  background: #f4851f;
  border-color: #f4851f;
  border-radius: 0;
  color: #ffffff !important;
}

/* ----------- Blog Page -----------*/
.blog-page .content-bottom {
  display: inline-block;
}

.blog-page article {
  padding-bottom: 13px;
}

.blog-page .post-content {
  border: none;
  padding: 35px 0;
}

.blog-page .meta-list li {
  padding-right: 15px;
}

.blog-page .entry-content {
  line-height: 28px;
  padding-top: 20px;
}

.blog-page .read-more {
  color: #7e878f;

  font-size: 16px;
  font-weight: 700;
  margin-top: 10px;
  padding-left: 0;
  text-transform: uppercase;
}

.blog-page .post-blockquote {
  letter-spacing: 1px;
}

.blog-page .attachmentlink {
  background: #00bfa5;
  letter-spacing: 0.5px;
}

.blog-page .attachmentlink a {
  color: #82edde;
  letter-spacing: 0;
}


/* --------- Sidebar Widgets ---------*/

.sidebar {
  padding-left: 45px;
}

.sidebar .widget {
  padding-bottom: 55px;
}

.sidebar .widget-title {
  color: #313332;

  font-size: 22px;
  font-weight: 700;
  padding-bottom: 20px;
  position: relative;
  text-transform: uppercase;
}

.search-form {
  position: relative;
}

.search-form input {
  border: 2px solid #eee;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #a6a6a6;

  font-size: 15px;
  font-style: italic;
  font-weight: normal;
  padding: 16px 35px 16px 15px;
  width: 100%;
}

.search-form input:focus {
  border-color: #eee;
  outline: 0;
}

.search-submit {
  background: transparent;
  border: none;
  color: #b2b2b2;
  font-size: 20px;
  position: absolute;
  top: 17px;
  right: 15px;
}

.search-submit:focus {
  border: none;
  outline: 0;
}

.widget-details {
  padding-top: 10px;
}

.category-list li {
  border-top: 1px solid #eee;
  display: block;
  position: relative;
}

.category-list li:last-child {
  border-bottom: 1px solid #eee;
}

.category-list li:before {
  content: "\f061";
}

.category-list li:before {
  color: #abb1b7;
  font-family: "FontAwesome";
  font-size: 11px;
  top: 19px;
  left: 0px;
  position: absolute;
}

.category-list a {
  color: #7e878f;
  display: block;

  font-size: 16px;
  line-height: 50px;
  padding-left: 15px;
}

.sidebar .post.media {
  margin: 0px 0 15px;
  padding: 0;
}

.sidebar .post-content {
  padding: 0px;
}

.sidebar .post-thumbnail.media-left {
  display: inline-block;
  float: left;
  margin-right: 10px;
  padding: 0px;
  width: 65px;
}

.sidebar .entry-title {
  font-family: "Montserrat", 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 14px !important;
  line-height: 20px;
  margin-top: -4px;
  padding: 0px;
}

.sidebar .entry-title a {
  color: #7e878f;
}

.sidebar .post-meta {
  padding-top: 10px;
  position: inherit;
}

.sidebar .post-meta:before,
.sidebar .post-meta:after {
  content: '';
  display: none;
}

.sidebar .author {
  color: #666666;
}

.dribbble-images li {
  max-width: 75px;
  margin-bottom: 4px;
}

.tag-list li {
  margin: 0 2px 6px 0;
}

.tag-list a {
  background: #eceff1;
  color: #8b8b8b;
  display: inline-block;

  font-size: 14px;
  padding: 7px 9px;
}

.tag-list a:hover {
  background: #f4851f;
  color: #ffffff !important;
}

.widget-details .text {

  line-height: 27px;
}

/* 14 ---------- Footer -----------*/

footer {
  background: #d1cdcb;
  color: #505050;
  font-family: "Montserrat", 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  padding: 30px 0;
}

.wrapper {
  min-height: 100%;
  margin-bottom: -50px;
}

.push {
  height: 50px;
}

footer a {
  color: #505050;
}

.footer-social a {
  text-align: center;
  font-size: 20px;
  line-height: 1;
  padding: 0 1rem;
}

footer h3 {
  font-size: 16px;
  margin: 0;
}

footer h3 strong {
  color: #353535;
  font-size: 20px;
}

footer h4 {
  font-size: 08px
}


.angles-container .angles-menu-item.js-menuBtn.m--btn .tooltip-inner,
.angles-container .angles-menu-item.js-menuBtn.m--btn .tooltip-arrow {
  display: none;
}

.tooltip {
  position: absolute !important;
  z-index: 999999999999999 !important;
}

.tooltip-inner {
  background-color: #ffffff;
  border-radius: 0;
  color: #7e878f;

  font-size: 12px;
  line-height: 15px;
  font-weight: normal;
  padding: 10px;
  text-transform: uppercase;
  z-index: 999999999999;
}

.tooltip-arrow {
  border: none !important;
  position: relative;
}

.tooltip-arrow:before {
  content: '';
  width: 10px;
  height: 10px;
  background: #ffffff;
  top: 12px;
  left: -5px;
  position: absolute;

  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.angles-container .menu-active .tooltip.fade {
  position: absolute;
  z-index: 3;
}

.header-top {
  -webkit-transition: all .25s cubic-bezier(.160, .560, .170, .999);
  -o-transition: all .25s cubic-bezier(.160, .560, .170, .999);
  transition: all .25s cubic-bezier(.160, .560, .170, .999);
}

.is-sticky .separator,
.is-sticky .header-top {
  display: none;
}

.is-sticky .main-content.menu-active .header-top {
  opacity: 1;
}

.top-svg {
  -webkit-transition: all .6s cubic-bezier(.160, .560, .170, .999);
  -o-transition: all .6s cubic-bezier(.160, .560, .170, .999);
  transition: all .6s cubic-bezier(.160, .560, .170, .999);
}

.is-sticky .top-svg {
  width: 0;
  height: 0;
}

.is-sticky .main-content.menu-active .top-svg {
  width: auto;
  height: auto;
}

.is-sticky .angles-menu-item.js-menuBtn.m--btn .icon-pattern {
  height: 5px;
  width: 5px;
}

.angles-container .is-sticky .angles-menu-item:after {
  font-size: 25px;
  left: -15px;
  top: 2px;
}

.angles-container .is-sticky .angles-menu-item.js-menuBtn.m--btn:nth-child(even):after {
  -webkit-transform: translate3d(23px, -5px, 0) rotate(0deg) !important;
  -ms-transform: translate3d(23px, -5px, 0) rotate(0deg) !important;
  transform: translate3d(23px, -5px, 0) rotate(0deg) !important;
}

.angles-container .is-sticky .angles-menu-item:nth-child(even) {
  left: -98px !important;
}

.angles-container .is-sticky .angles-wrapper_menu.menu-active .angles-menu-item:nth-child(even) {
  left: 0 !important;
}

/********************************
PRELOADER STYLING
********************************/
/*.preloader {
  background: #eee center center no-repeat !important;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  z-index: 99999;
  overflow: hidden !important;
}

.angels-loader {
  width: 125px;
  height: 140px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 0 auto;
  background-image: url('../img/pre-loader.gif');
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: cover;
  background-size: cover;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
} */

/*--------------- style-switcher ------------------*/
.style-switcher {
  background: #FDFAFA;
  width: 220px;
  position: fixed;
  right: -220px;
  top: 116px;
  z-index: 120000;
  border: 1px solid #ffffff;
  padding: 0 20px 20px 20px;
  -webkit-transition: all .2s ease-out;
  -o-transition: all .2s ease-out;
  transition: all .2s ease-out;
}

.style-switcher.panel-merge {
  right: 0px !important;
  -webkit-transition: all .2s ease-out;
  -o-transition: all .2s ease-out;
  transition: all .2s ease-out;
}

.style-switcher h3 {
  color: #313332;
  font-size: 16px;
  border-bottom: 1px solid #ddd;
  margin: 0 -20px 20px -20px;
  padding: 15px 20px;
}

.style-switcher .style-title {
  font-size: 14px;
  color: #313332;
}

.style-switcher ul {
  padding: 20px 0;
  list-style: none;
  margin-bottom: 10px;
  display: inline-block;
  border-bottom: 1px dashed #ddd;
  width: 100%;
  padding-bottom: 20px;
}

.style-switcher ul li {
  cursor: pointer;
}

.style-switcher ul.color-list li.cl-item {
  border: 1px solid #fff;
  width: 25px;
  height: 25px;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
}

.style-switcher .toggle {
  background: #313332;
  border: 1px solid #ddd;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  position: absolute;
  overflow: hidden;
  left: -42px;
  top: 48px;
  width: 42px;
  text-align: center;
  outline: 0;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  .style-switcher .toggle {
    top: 47px;
  }
}

/* *********************************************************************° */
/* ILION */

/* .site-header,
.colophon {
  display: none;
} */

.header-top img {
  max-width: 156px;
}



.item-details.-vaga .item-title {
  font-size: 15px;
  font-weight: 800;
  color: #333231;
  display: block;
}

.item-details.-vaga .item-subtitle {

  font-size: 15px;
  font-weight: 800;
  color: #242725;
  display: block;
  margin-top: 1rem;
}

.facts.-vagas .col-md-3.col-sm-6 {
  background: #EAE5E3;
  border: 7px solid white;
  position: relative;
}

.facts.-vagas .item {
  min-height: 200px;
  display: block;
}

.facts.-vagas .item-icon,
.facts.-vagas .item-about {
  font-family: "Montserrat", 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  color: #2c3035 !important;
}

.facts.-vagas .countdown {
  color: #fff;
  font-size: 13px;
  font-weight: 800;
  background-color: #f4851f;
  padding: 10px 2rem;
  line-height: 1.2;
  display: inline-block;
  max-width: 100%;
}

.facts.-vagas .countdown .count-number {
  display: inline-block;
  color: inherit;
  vertical-align: top;
  padding: 0;
}

.facts.-vagas .item-about {
  font-size: 16px;
  font-weight: 600;
  padding: 20px;
  display: block;
}

.facts.-vagas .item-local {
  display: block;
}

.facts.-vagas .wpcf7-submit {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .facts.-vagas .wpcf7-submit {
    margin-top: 50px;
    margin-bottom: 50px;
    max-width: 400px;
    background: transparent;
    border-color: #d6d7d8;
    color: #2c3035;
    max-height: 65px;
  }
}

.interest-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* SERVICOS */
.servicos .facts .col-md-3.col-sm-6 {
  margin-top: 64px;
  margin-bottom: 64px;
  padding: 55px;
}


.servicos .destaque {
  background-color: #1F5B9F;
  color: white;
  text-align: center;
  padding: 20px;
}

.servicos .destaque h2 {
  font-size: 20px;
  font-weight: bold;
  font-family: "Montserrat", 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.servicos .servico {
  padding: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.servicos .servico.-empresa {
  background-image: url(../img/box1.jpg);
}

.servicos .servico.-usuario {
  background-image: url(../img/box2.jpg);
}

.servicos .servico .texto {
  padding: 100px;
  font-family: "Montserrat", 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: bold;
  color: white;
  text-align: center;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
}

.servicos .servico .texto .titulo {
  font-size: 40px;
  font-weight: bold;
}

.servicos .servico .texto p {
  font-size: 18px;
  font-weight: 600;
  color: white;
  margin-top: 35px;
  margin-bottom: 40px;
}

.servicos .servico .wpcf7-submit {
  background-color: white;
  border-color: white;
  color: black;

  font-size: 13px;
  width: 200px;
  /* max-width: 235px; */
  max-height: 65px;
  text-transform: uppercase;
  margin-bottom: 0;
}

/* VAGAS */
.vagas {
  padding: 0;
}

.vagas .row {
  margin: 0;
}

.vagas .banner {
  background-image: url(../img/vagas.jpg);
}

.filtro {
  margin-top: 40px;
  margin-bottom: 60px;
  text-transform: uppercase;
  -webkit-appearance: none
}

.vagas-select {
  margin-top: 40px;
  margin-bottom: 80px;
}

.filtro .wpcf7-submit {
  background-color: black;
  border-color: black;
  color: white;
  font-family: "Montserrat", 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 13px;
  width: 200px;
  /* max-width: 235px; */
  max-height: 65px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.filtro .select-filtro {
  height: 52px;
  width: 200px;
  background: white;
  border: 2px solid #eee;
  color: #6c6d6d;
  padding: 0 1rem;
  font-size: 15px;
}


.vagas .destaque {
  background-color: #1F5B9F;
  color: white;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.vagas .destaque h2 {
  font-size: 20px;
  font-weight: 500;
  font-family: "Montserrat", 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.info-vaga {
  background-color: #EAE5E3;
  padding-top: 2%;
}

.info-vaga .lista-item {
  font-family: "Montserrat", 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  color: #34373a;
  padding: 25px;
}

.info-vaga .lista-item h2 {
  font-size: 15px;
  font-weight: 600;
  margin-top: 2%;
}

.info-vaga .lista-item p {
  font-size: 14px;
  font-weight: 400;
  color: #727475;
}

.info-vaga .lista-item img {
  max-width: 13%;
}

.vagas_texto p {
  margin: 0 0 20px 0;
  line-height: 1.4;
  color: #2c3035;
  font-family: "Montserrat", 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: 400;
}

.formulario-inscricao .bottom-padding {
  clear: both;
  padding-top: 1px;
}

.formulario-inscricao .content-bottom {
  padding: 60px 0;
}

.historico-info {
  background: #f4f4f4;
  border-radius: 3px;
}

.padding-row {
  padding: 16px;
}

.services {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.entry-content {
  margin-bottom: 100px;
}

.info-vaga {
  background-color: #EAE5E3;
  padding-top: 2%;
}

.info-vaga .lista-item {
  font-family: "Montserrat", 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  color: #34373a;
  padding: 25px;
}

.info-vaga .lista-item h2 {
  font-size: 15px;
  font-weight: 600;
  margin-top: 2%;
}

.info-vaga .lista-item p {
  font-size: 14px;
  font-weight: 400;
  color: #727475;
}

.info-vaga .lista-item img {
  max-width: 13%;
}

.vagas_texto p {
  margin: 0 0 20px 0;
  line-height: 1.4;
  color: #2c3035;
  font-family: "Montserrat", 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: 400;
}

.services {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.entry-content {
  margin-bottom: 100px;
}

/* BANNER */

.banner {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: 60vh;
  padding: 80px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  color: white;
  text-align: center;
}

.banner h1 {
  font-size: 50px;
  font-family: "Montserrat", 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: 800;
  margin: 0;
}

.banner h2 {
  font-family: "Montserrat", 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

.banner h1+h2 {
  margin-top: 20px;
}

.single .banner {
  background-image: url(../img/vivencial.jpeg);
  height: 70vh;
}

.blog .banner {
  background-image: url(../img/banners-blog.jpg);
}

.clientes .banner {
  background-image: url(../img/banner-clientes.jpg);
}

.servicos .banner {
  background-image: url(../img/banner-servicos.jpg);
}

.blog .banner {
  background-image: url(../img/banners-blog.jpg);
}

.contato .banner {
  background-image: url(../img/banners-contato.jpg);
}

.cadastro .banner {
  background-image: url(../img/banner-cadastro.jpg);
}

.inscricao .banner {
  background-image: url(../img/banner-inscricao.jpg);
}

#html br:only-child {
  display: none !important;
}

@media only screen and (max-width: 640px) {
  .header-top img {
    max-width: 80px;
  }

  .angles-slider .sp-layer.main-title {
    text-align: center;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    font-size: 15px;

  }

  .angles-slider .sp-layer.sub-title {
    text-align: center;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    font-size: 15px;
    word-break: break-word !important;
    white-space: normal !important;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 20%;
    max-width: 100%;
  }

  .facts.-vagas .wpcf7-submit {
    font-size: 12px;
  }

  .interest-list .col-sm-2.col-xs-6 {
    padding: 2px;
  }

  .interest-list {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }

  .col-md-6 {
    width: 100%;
  }

  .interest-list .item {
    height: 100%;
    width: 100%;
    margin: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .item-icon {
    font-size: 1rem
  }

  .interest-list .item-title {
    font-size: 10px;
  }

  .interest-list img {
    max-width: 40%;
  }

  .vagas .destaque h2 {
    font-size: 15px;
  }

  .servicos .destaque h2 {
    font-size: 15px;

  }

  .filtro .select-filtro {
    height: 52px;
    width: 50%;
    float: left;
    font-size: 10px;
  }

  .filtro .wpcf7-submit {
    width: 110px;
  }

  .services {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }

  #contact .item-title {
    padding-top: 10px;
    padding-bottom: 0;
  }

  footer.rodape {
    height: 80px;
    padding: 20px 0 20px 0;
    text-align: center;
  }

  footer.rodape h3 {
    font-size: 13px;
  }

  .wpcf7-form-control {
    border: 1px solid #adadad;
    font-family: "Montserrat", 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 12px;
  }

  .informações .wpcf7-form-control {
    width: 90%;
    margin-bottom: 5px;
  }

  .formação .wpcf7-form-control {
    width: 90%;
    margin-bottom: 5px;
  }

  .historico .wpcf7-form-control {
    margin-top: 5px;
    margin-bottom: 0px;
    width: 90%;
  }

  .motivo .wpcf7-form-control {
    margin-top: 5px;
    padding: 10px 10px;
    width: 90%;
  }

  .seletores label {
    font-size: 12px;
    font-weight: 400;
  }

  .dados label {
    font-size: 12px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
  }

  .historico .wpcf7-submit {
    background: #d1cdcb;
    font-size: 12px;
    width: 40%;
    padding-bottom: 15px;
  }
}

/* AJUSTES */

.rodape .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.angles-wrapper .angles-menu-item.m--btn::after {
  width: 150px;
}

.angles-wrapper.is-sticky .angles-menu-item.m--btn::after {
  width: 100px;
}

.angles-slider .main-title {
  font-family: "Montserrat", 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  letter-spacing: -0.05px;
  font-weight: 600;
}

.button-registered-forgout {
  text-align: center;
  display: inline-block;
  width: 100%;
}

.float-right {
  float: right;
}

#containerNotRegistered,
#containerForgot {
  display: none;
  opacity: 1;
  -webkit-animation-name: fadeInOpacity;
  animation-name: fadeInOpacity;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

@-webkit-keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/*
 *
 * AJUSTES
 */

.angles-slider .sp-slide {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.angles-slider .main-title,
.angles-slider .sub-title {
  top: auto !important;
  left: auto !important;
  right: auto !important;
  bottom: auto !important;
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  position: static !important;
  margin: 0 !important;
  padding: 0 !important;
  white-space: normal !important;
  max-width: 60vw !important;
  width: 100% !important;
  height: auto !important;
}

.angles-slider .sp-slide .title {
  line-height: 1;
}

.angles-slider .sp-slide .sub-title {
  margin-top: 2rem !important;
  line-height: 1.4;
}

.page.-job .separator svg,
.page.-job-details .separator svg {
  fill: transparent;
}

.formulario-inscricao .section-title {
  padding-bottom: 0;
}

.social-list li {
  display: inline-block;
  vertical-align: top;
}

.quote-icon .fa {
  line-height: 64px;
  color: #808080;
}

.owl-buttons .fa {
  font-size: 3rem;
  line-height: 38px;
  vertical-align: top;
}

.post-content p br {
  display: none !important;
}

#html .form-control {
  padding: 0 2rem;
  height: 40px;
  line-height: 40px;
  margin: 0;
  background-color: white;
  border: 0;
  border-radius: 0;
  box-shadow: inset 0 0 0 2px #eee;
}

#html .form-control:hover {
  box-shadow: inset 0 0 0 2px #ddd;
}

#html .form-control:focus {
  box-shadow: inset 0 0 0 2px #f4851f;
}

#html .form-control-wrap {
  display: flex;
}

#html .form-control-wrap+.form-control-wrap {
  margin-top: 2rem;
}

#html .form-control-wrap .form-control+.form-control {
  margin-left: 2rem;
}

#html textarea.form-control {
  line-height: 1.5;
  height: auto;
  min-height: 80px;
}

.formulario-inscricao .title {
  font-family: "Montserrat", 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: 600;
  font-size: 2rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.formulario-inscricao .dados .radioButton {
  display: inline-block;
  margin-left: 20px;
  margin-top: 0;
}

.formulario-inscricao .dados p {
  margin: 2rem 0;
}

.formulario-inscricao .enviar {
  margin-top: 4rem;
}

#html .btn {
  border: 0;
  text-shadow: none;
  font-family: "Montserrat", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: bold;
}

.btn.-medium {
  padding: 0 4rem;
  height: auto;
  line-height: 5rem;
  font-size: 2rem;
}

.btn-primary {
  background-color: #f4851f;
  color: white;
}

.btn-primary:hover {
  background-color: #f39741;
}

.btn-contrast {
  background-color: #1976D2;
  color: white;
}

.btn-contrast:hover {
  background-color: rgb(38, 131, 224);
}

.mobile-header {
  display: none;
}

.info-vaga .row {
  display: flex;
  flex-flow: row wrap;
}

@media (max-width: 640px) {
  #html .angles-wrapper .angles-menu-item.m--btn:after {
    width: 40px;
    height: 40px;
    top: 15px;
    line-height: 40px;
  }

  #html .content-bottom {
    padding: 32px 0;
  }

  #html .bottom-padding {
    padding-top: 32px;
  }

  #html .banner {
    height: 50vh;
    padding: 50px 20px;
    justify-content: flex-end;
  }

  #html .banner h1 {
    font-size: 28px;
  }

  #html .banner h2 {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.4;
  }

  #html .banner h1+h2 {
    margin-top: 13px;
  }

  #html .section-title {
    padding-bottom: 0;
    margin-bottom: 2rem;
  }

  #html .testimonial-slider .item {
    padding: 32px;
  }

  #html .form-control-wrap {
    flex-flow: column wrap;
  }

  #html .form-control-wrap .form-control+.form-control {
    margin-left: 0;
    margin-top: 2rem;
  }

  .servicos .servico .texto {
    padding: 64px 15px;
  }

  .angles-slider .main-title,
  .angles-slider .sub-title {
    max-width: 90vw !important;
  }

  .angles-slider .sp-layer.main-title {
    font-size: 28px !important;
  }

  .angles-slider .sp-layer.sub-title {
    font-size: 15px !important;
    font-weight: normal;
  }

  .facts-items .row {
    margin: 0;
  }

  .servicos .servico .texto .titulo {
    font-size: 28px;
  }

  .servicos .servico .texto p {
    margin: 20px 0 30px 0;
  }

  .servicos .servico .texto p br {
    display: none;
  }

  #html .facts .col-md-3.col-sm-6 {
    border: 2px solid #eee;
    padding: 20px;
  }

  #html .facts .col-md-3.col-sm-6+.col-md-3.col-sm-6 {
    margin-top: 20px;
  }

  #html .facts .col-md-3.col-sm-6:not(:last-child) {
    border-bottom: 0;
  }

  .servicos .destaque h2,
  .vagas .destaque h2 {
    line-height: 1.5;
  }


  #html .site-header,
  #html .angles-wrapper_menu {
    display: none !important;
  }

  .mobile-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #313332;
    color: #eee;
    display: block;
  }

  .mobile-header .mobile-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mobile-header .logo-holder {}

  .mobile-header .img-logo {
    max-width: 64px;
    max-height: 64px;
  }

  .mobile-header .mobile-menu {
    position: static;
  }

  .mobile-header .mobile-menu-list {}

  .mobile-header .menu-item {
    display: block;
    line-height: 4rem;
    padding: 0 2rem;
  }

  .mobile-header .menu-item a:hover {
    color: #f4851f;
  }

  .mobile-header .btn-menu {
    background-color: transparent !important;
    padding: 1rem;
    font-size: 3rem;
    line-height: 0.8;
    color: white !important;
  }

  .mobile-header .btn-menu .fa {
    line-height: 0.85;
  }

  @keyframes animeDropdown {
    from {
      transform: translateY(-3rem);
      opacity: 0;
    }
  }

  .mobile-header .dropdown-backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-transition: all .4s cubic-bezier(.160, .560, .170, .999);
    -o-transition: all .4s cubic-bezier(.160, .560, .170, .999);
    transition: all .4s cubic-bezier(.160, .560, .170, .999);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .mobile-header .dropdown-menu {
    left: 0;
    right: 0;
    width: 100vw;
    margin: 0;
    border: 0 none;
    border-radius: 0;
    animation: animeDropdown 0.5s 0s normal both;
    animation-timing-function: cubic-bezier(.160, .560, .170, .999);
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -7px rgba(0, 0, 0, 0.2);
  }

  .top-space {
    display: none;
  }

  #html .wpcf7-submit {
    margin-bottom: 0;
  }

  .google-map {
    margin-top: 20px;
  }

  #html .facts .wpcf7-submit {
    margin-bottom: 4rem;
  }

  #html .text-blog {
    margin: 0;
  }

  #html .entry-content {
    margin: 0;
    padding: 0;
  }
}

@media (min-width: 768px) {
  .servicos .row {
    display: flex;
  }

  .blog-posts {
    display: flex;
    flex-flow: row wrap;
  }
}

input[type="date"]:before {
  content: attr(placeholder) !important;
  color: #aaa;
  margin-right: 0.5em;
}

input[type="date"]:focus:before,
input[type="date"]:valid:before {
  content: "";
}

input[type="date"]:focus:before,
input[type="date"]:valid:before {
  content: "";
}

#homeSliderVagas .col-md-3.col-sm-6 {
  width: 100%;
}

#angles-slider .btn-cadastro {
  display: none;
}

@media (min-width: 641px) {
  #angles-slider .btn-cadastro {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    margin: 3rem;
    width: auto;
    z-index: 100;
    padding: 0 2rem;
    line-height: 4rem;
  }
}

.menu-item.-cadastro {
  background: #f4851f;
  color: #fff;
  font-weight: 700;
  font-family: "Montserrat", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: bold;
}

.dropdown-menu {
  padding: 0;
} 